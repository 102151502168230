import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import LockIcon from '@mui/icons-material/Lock';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import moment from 'moment';
import './Login.less';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { LoginLabels } from '../../theme/ThemeLabels';
import { ILoginPayload } from '../../libs/models/LoginModels';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import { routes } from '../../navigation/Routes';

interface ILoginProps {}

const Login: React.FC<ILoginProps> = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [loginCredentials, setLoginCredentials] = React.useState<ILoginPayload>({
    username: '',
    password: '',
  });
  const { loading, loggedIn } = useAppSelector(
    ({ login: { loading, user, expiry } }: IApplicationState) => ({
      loading,
      loggedIn: !isEmpty(user) && !moment().isAfter(moment(expiry * 1000)),
    }),
  );
  const dispatch = useDispatch();

  const dispatcher = {
    loginUser: () => dispatch(LoginActions.loginStart(loginCredentials)),
  };

  useEffect(() => {
    if (loggedIn) {
      history.replace(routes.dashboard);
    }
  }, [loggedIn]);

  return (
    <Grid container className="login-container">
      <Grid item xs={4} className="login-box-container">
        <span className="login-welcome">{LoginLabels.welcomeMessage}</span>
        <Grid className="login-box">
          <Grid item xs={12}>
            <LockIcon className="lock-icon" />
            <span className="sign-in">{LoginLabels.signIn}</span>
          </Grid>
          <span className="horizontal-separator"></span>
          <Grid xs={12} item container alignItems={'flex-end'} justifyContent={'space-evenly'}>
            <Grid item xs={5} className="login-input">
              <span className="login-label">
                {LoginLabels.username}
                <Tooltip title={LoginLabels.loginTooltiop} arrow>
                  <HelpOutlinedIcon />
                </Tooltip>
              </span>
              <TextField
                variant="outlined"
                fullWidth
                onChange={(e) =>
                  setLoginCredentials((currState) => ({ ...currState, username: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={5} className="login-input">
              <span className="login-label">{LoginLabels.password}</span>
              <TextField
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onChange={(e) =>
                  setLoginCredentials((currState) => ({ ...currState, password: e.target.value }))
                }
                sx={{ backgroundColor: 'white' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                size="large"
                className="sign-in-button"
                disabled={
                  loading ||
                  isEmpty(loginCredentials.username) ||
                  isEmpty(loginCredentials.password)
                }
                onClick={() => dispatcher.loginUser()}
              >
                {LoginLabels.signIn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
