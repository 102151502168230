import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './Loader.less';

const Loader: React.FC<any> = () => {
  const { isLoading } = useAppSelector(
    ({ login, transaction, chatbot, nfcu }: IApplicationState) => ({
      isLoading: login.loading || transaction.loading || chatbot.loading || nfcu.loading,
    }),
  );
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }} open={isLoading}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="custom_gradient">
            <stop offset="0%" className="loader-start" />
            <stop offset="10%" className="loader-middle" />
            <stop offset="100%" className="loader-stop" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress className="loading-indicator" size={'10rem'} thickness={5} />
    </Backdrop>
  );
};

export default Loader;
