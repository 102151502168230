import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './Chatbot.less';
import ChatbotFab from '../../assets/images/chatbot-fab.png';
import { ChatbotLabels } from '../../theme/ThemeLabels';
import { ChatbotActions } from '../../libs/store/actions/ChatbotActions';

const Chatbot: React.FC<any> = () => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const { data } = useAppSelector(({ chatbot: { data } }: IApplicationState) => ({
    data,
  }));
  const dispatch = useDispatch();

  const dispatcher = {
    sendQuery: (queryString: string) => dispatch(ChatbotActions.getChatResponseStart(queryString)),
    clearResponse: () => dispatch(ChatbotActions.clearResponse()),
  };

  const closeChat = () => {
    setIsChatOpen(false);
    dispatcher.clearResponse();
  };

  const submitQuery = () => {
    dispatcher.sendQuery(query);
    setQuery('');
  };
  return (
    <>
      <Fab size="large" className="chatbot-fab" onClick={() => setIsChatOpen(true)}>
        <img src={ChatbotFab} alt="Chat Bot" />
      </Fab>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isChatOpen}
        onClick={closeChat}
      >
        <div className="chatbot-container" onClick={(e) => e.stopPropagation()}>
          {isEmpty(data.response) ? (
            <>
              <Grid container justifyContent={'flex-end'}>
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label="close chat"
                  onClick={closeChat}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid container alignItems={'center'} direction={'column'} className="chatbot-header">
                <Grid item>{ChatbotLabels.headerLine1}</Grid>
                <Grid item>{ChatbotLabels.headerLine2}</Grid>
              </Grid>
            </>
          ) : (
            <Grid container>
              <Grid container className="question">
                <Grid item xs={11}>
                  {data.question}
                </Grid>
                <Grid item xs={1}>
                  <CancelOutlinedIcon
                    sx={{ cursor: 'pointer' }}
                    aria-label="close chat"
                    onClick={closeChat}
                  />
                </Grid>
              </Grid>
              <Grid container className="answer">
                {data.response}
              </Grid>
            </Grid>
          )}
          <TextField
            variant="outlined"
            className={`chatbot-query ${isEmpty(data.response) ? '' : 'filled'}`}
            placeholder={ChatbotLabels.placeHolder}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') submitQuery();
            }}
            value={query}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="send query"
                    disabled={isEmpty(query)}
                    onClick={submitQuery}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    <SendRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Backdrop>
    </>
  );
};

export default Chatbot;
