import { ILoginPayload, ILoginResponse } from '../../models/LoginModels';
import { IStoreAction } from '../StoreHelper';

export enum LoginActionTypes {
  LOGIN_START = 'LOGIN_START',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT = 'LOGOUT',
}

export type LoginActionPayload = ILoginPayload | ILoginResponse | Error | null;

export type LoginAction = IStoreAction<LoginActionTypes, LoginActionPayload>;

export class LoginActions {
  public static loginStart = (data: ILoginPayload): LoginAction => ({
    type: LoginActionTypes.LOGIN_START,
    data,
  });
  public static loginSuccess = (data: ILoginResponse): LoginAction => ({
    type: LoginActionTypes.LOGIN_SUCCESS,
    data,
  });
  public static loginError = (data: Error): LoginAction => ({
    type: LoginActionTypes.LOGIN_ERROR,
    data,
  });
  public static Logout = (): LoginAction => ({
    type: LoginActionTypes.LOGOUT,
  });
}
