import { ILoginPayload, ILoginResponse } from '../../models/LoginModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class LoginService {
  private readonly LoginService: ApiServiceBase = new ApiService(ServiceType.None, ServerType.login);

  public loginUser(payload: ILoginPayload): Promise<ILoginResponse> | ILoginResponse {
    const route: string[] = [API_ROUTE.LOGIN];
    return this.LoginService.get({ route: route, query: { ...payload } });
  }
}

const loginService = new LoginService();
export default loginService;
