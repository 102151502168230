import * as axios from 'axios';
import { DUMMY_ACCESS_TOKEN } from '../../AppConstants';
import { ServerEndpoints } from '../../AppSettings';
import AxiosInstance from './AxiosInstance';
import { HttpContentType } from './HttpContentType';
import { storage, STORAGE_CONSTANTS } from './LocalStorage';
import { IMultipartFormData } from './MultipartFormData.data';
import { QueryPath } from './QueryPath.data';
import { ServerType, ServiceType } from './ServiceType.data';

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly serverType: ServerType;
  protected isFetchingToken = false;
  protected tokenSubscribers: any = [];

  constructor(serviceType: ServiceType, serverType: ServerType) {
    this.serviceType = serviceType;
    this.serverType = serverType;
  }

  public abstract get<T = void>(path: QueryPath): Promise<T> | T;

  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract patch<T = void>(path: QueryPath, body: any): Promise<T> | T;

  public abstract delete<T = void>(path: QueryPath): Promise<T>;

  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
  ): Promise<T> | T;

  public abstract putMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
  ): Promise<T> | T;

  public abstract patchMultipart<T = void>(
    path: QueryPath,
    data: IMultipartFormData[],
  ): Promise<T> | T;

  /* tslint:disable:cyclomatic-complexity */
  public processError(error: any) {
    const errorCode = error.response ? error.response.status || 500 : 500;
    switch (errorCode) {
      case 404:
        return new Error('The request is not found');
      case 500:
        return new Error('Internal server error');
      case 401:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...error.response.data,
        };
      case 400:
      case 422: {
        if (error.response.data.errors) {
          const err = error.response.data.errors;

          if (err instanceof Array) {
            const errArr = err;

            if (errArr.length > 0 && errArr[0]) {
              if (errArr[0].message) {
                return new Error(errArr[0].message.toString());
              } else if (errArr[0].Message) {
                return new Error(errArr[0].Message.toString());
              } else {
                return new Error(errArr[0].toString());
              }
            }
          } else if (err.message) {
            return new Error(err.message.toString());
          } else if (err.Message) {
            return new Error(err.Message.toString());
          } else {
            return new Error(err.toString());
          }
        } else if (error.response.data) {
          return new Error(error.response.data.toString());
        }

        return new Error('Internal server error');
      }
      default:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return error;
    }
  }

  /* tslint:enable */
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    return {
      headers: {
        'Content-Type': contentType.toString(),
        'Authorization': `Bearer ${
          storage.getItem(STORAGE_CONSTANTS.accessToken) || DUMMY_ACCESS_TOKEN
        }`,
      },
    };
  }

  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    const onTokenRefreshed = (error: Error | null) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.tokenSubscribers.map((cb: any) => cb(error));
      this.tokenSubscribers = [];
    };

    const forceLogout = () => {
      this.isFetchingToken = false;
      storage.clearAll();
      window.location.href = `/#/login`;
    };

    instance.interceptors.response.use(
      (response: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response;
      },
      (error: any) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        if (
          error.response.status !== 403 &&
          error.response.status !== 401 &&
          error.response.status !== 400
        ) {
          return Promise.reject(error);
        }

        if (error.config.url.includes('login')) {
          return Promise.reject(error);
        }

        // If refreshToken API, force logout
        if (error.config.url.includes('refresh_token') && error.response.status === 400) {
          onTokenRefreshed(new Error('Unable to refresh access token'));
          forceLogout();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
    return instance;
  }

  // Generates url: {ServerEndpoints[ServerType]}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  // Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath): string {
    const baseURL = ServerEndpoints[this.serverType];
    let url: string = this.serviceType ? `${baseURL}/${this.serviceType}` : `${baseURL}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== 'undefined') {
            url += `/${route}`;
          }
        }
      }

      if (path.query) {
        let separator = '?';

        for (const name in path.query) {
          if (path.query[name]) {
            url += `${separator}${encodeURIComponent(name)}=${encodeURIComponent(
              path.query[name]!.toString(),
            )}`;
            separator = '&';
          }
        }
      }
    }
    return url;
  }

  protected prepareMultiPartForm(data: IMultipartFormData[]): FormData {
    //  It is expected that if a file is to be given a name that is different from the file system name,
    //  the interface created for such objects will have a key named "formDataName" and another named "file" (names are self-explanatory)

    const formData = new FormData();
    for (const item of data) {
      const { content, name } = item;
      if (Array.isArray(content) && content.length > 0) {
        for (const element of content) {
          if (
            typeof element === 'object' &&
            Object.prototype.hasOwnProperty.call(element, 'formDataName')
          ) {
            formData.append(name, element.file, element.formDataName);
          } else {
            formData.append(name, element);
          }
        }
      } else if (
        typeof content === 'object' &&
        content !== null &&
        Object.prototype.hasOwnProperty.call(content, 'formDataName')
      ) {
        formData.append(name, content.file, content.formDataName);
      } else {
        formData.append(name, content);
      }
    }
    return formData;
  }
}
