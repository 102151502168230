import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { isEmpty } from 'lodash';
import './Dashboard.less';
import { DashboardMenu } from '../../theme/ThemeLabels';
import Accounts from '../../components/Accounts/Accounts';
import Transactions from '../../components/Transactions/Transactions';
import NFCUComponent from '../../components/NFCUComponent/NFCUComponent';
import Chatbot from '../../components/Chatbot/Chatbot';

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
  const [selectedMenu, setSelectedMenu] = useState<DashboardMenu>(DashboardMenu.ACCOUNTS);
  const [selectedAccount, setSelectedAccount] = useState<string>();

  const handleChange = (e: React.SyntheticEvent, newValue: DashboardMenu) => {
    setSelectedMenu(newValue);
  };

  const renderTabContent = () => {
    switch (selectedMenu) {
      case DashboardMenu.NFCU_API:
        return <NFCUComponent />;
      case DashboardMenu.ACCOUNTS:
      default:
        return isEmpty(selectedAccount) ? (
          <Accounts setSelectedAccount={setSelectedAccount} />
        ) : (
          <Transactions selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
        );
    }
  };

  return (
    <Grid container className="dashboard-container">
      <Grid container className="navigation-container">
        <Tabs value={selectedMenu} onChange={handleChange} className="navigation-tabs">
          {Object.values(DashboardMenu).map((tabitem, index) => (
            <Tab
              className={`navigation-tab ${index > 1 ? 'disabled-click' : ''}`}
              disabled={index > 1}
              key={tabitem}
              value={tabitem}
              label={tabitem}
            />
          ))}
        </Tabs>
      </Grid>
      {renderTabContent()}
      <Chatbot />
    </Grid>
  );
};

export default Dashboard;
