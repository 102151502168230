/// <summary>
// DO NOT append or prepend '/' here!
export enum ServiceType {
  BuildDeploy = 'dca',
  None = '',
}

// Add base URLs and refresh token logic(if the server supports)
// in ApiServiceBase.ts for a new server added here
export enum ServerType {
  dca = 'DCA',
  login = 'login',
  transaction = 'transaction',
  balance = 'balance',
  contacts = 'contacts',
  payments = 'payments',
  nfcu = 'nfcu',
  chatbot = 'chatbot',
}
