import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';
import IGenAIState from '../state/GenAIState';
import ToastState from '../state/ToastState';
import ILoginState from '../state/LoginState';
import ITransactionState from '../state/TransactionState';
import ICommonState from '../state/CommonState';
import INFCUState from '../state/NFCUState';
import IChatbotState from '../state/ChatbotState';
import { genAIReducer } from './GenAIReducer';
import { toastReducer } from './ToastReducer';
import { loginReducer } from './LoginReducer';
import { transactionReducer } from './TransactionReducer';
import { CommonReducer } from './CommonReducer';
import { NFCUReducer } from './NFCUReducer';
import { ChatbotReducer } from './ChatbotReducer';

export interface IApplicationState {
  genAI: IGenAIState;
  toast: ToastState;
  login: ILoginState;
  transaction: ITransactionState;
  common: ICommonState;
  nfcu: INFCUState;
  chatbot: IChatbotState;
}

const appReducer = combineReducers<IApplicationState>({
  genAI: genAIReducer,
  toast: toastReducer,
  login: loginReducer,
  transaction: transactionReducer,
  common: CommonReducer,
  nfcu: NFCUReducer,
  chatbot: ChatbotReducer,
});
const rootReducer: (state: IApplicationState | undefined, action: AnyAction) => IApplicationState =
  (state, action) => {
    // Emptying redux state on LOGOUT_ACTION
    if (action.type === 'TOGGLE_LOGOUT') {
      if (!action.data) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
      }
    }
    return appReducer(state, action);
  };

export const useAppSelector: TypedUseSelectorHook<IApplicationState> = useSelector;

export default rootReducer;
