import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch } from 'react-redux';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './Accounts.less';
import { AccountsLabels, ApplicationLabels, DummyLabels } from '../../theme/ThemeLabels';
import { getCurrencyFormat } from '../../libs/helper/HelperFunctions';
import { TransactionActions } from '../../libs/store/actions/TransactionActions';

interface IAccountsProps {
  setSelectedAccount: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Accounts: React.FC<IAccountsProps> = ({ setSelectedAccount }) => {
  const { balance, accountNumber } = useAppSelector(
    ({ transaction: { balance }, login: { accountNumber } }: IApplicationState) => ({
      balance,
      accountNumber,
    }),
  );

  const dispatch = useDispatch();

  const dispatcher = {
    getBalance: () => {
      return dispatch(TransactionActions.getBalanceStart(accountNumber));
    },
  };

  useEffect(() => {
    dispatcher.getBalance();
  }, []);

  return (
    <Grid container className="dashboard-content">
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid
          xs={6}
          item
          container
          columnGap={3}
          alignSelf={'center'}
          alignItems={'center'}
          className="dashboard-header-left"
        >
          <span className="dashboard-header-left-accounts">{AccountsLabels.accounts}</span>
          <span className="dashboard-header-left-options">
            <ModeIcon fontSize="small" />
            <span>{AccountsLabels.editAccounts}</span>
          </span>
          <span className="dashboard-header-left-options">
            <PrintIcon fontSize="small" />
            <span>{AccountsLabels.print}</span>
          </span>
        </Grid>
        <Grid
          xs={3}
          item
          container
          direction={'column'}
          alignItems={'flex-end'}
          className="dashboard-header-right"
        >
          <span className="dashboard-header-right-label">{AccountsLabels.totalCash}</span>
          <span className="dashboard-header-right-value">{getCurrencyFormat(balance)}</span>
        </Grid>
      </Grid>
      <Grid container className="dashboard-accounts">
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <span className="dashboard-accounts-label">{AccountsLabels.checkingAccount}</span>
          <span className="dashboard-accounts-cash">{getCurrencyFormat(balance)}</span>
        </Grid>
        <Grid container className="dashboard-accounts-list">
          {DummyLabels.accounts.map((account, index) => (
            <Card key={account.name} className="dashboard-account">
              <CardActionArea
                className="dashboard-account-action"
                onClick={() => setSelectedAccount(account.name)}
              >
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid xs={5} item container alignItems={'center'}>
                    <span className="dashboard-account-currency">{ApplicationLabels.dollar}</span>
                    <span className="dashboard-account-name">{account.name}</span>
                  </Grid>
                  <Grid
                    xs={4}
                    item
                    container
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-end'}
                  >
                    <span className="dashboard-account-balance">
                      {getCurrencyFormat(index === 0 ? balance : 0)}
                    </span>
                    <span className="dashboard-account-label">
                      {AccountsLabels.availableBalance}
                    </span>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Accounts;
