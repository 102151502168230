import {
  IContact,
  IDepositPayload,
  ISendPaymentPayload,
  ITransaction,
} from '../../models/TransactionModels';
import { IStoreAction } from '../StoreHelper';

export enum TransactionActionTypes {
  GET_TRANSACTIONS_START = 'GET_TRANSACTIONS_START',
  GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR',

  GET_BALANCE_START = 'GET_BALANCE_START',
  GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS',
  GET_BALANCE_ERROR = 'GET_BALANCE_ERROR',

  GET_CONTACTS_START = 'GET_CONTACTS_START',
  GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR',

  DEPOSIT_FUNDS_START = 'DEPOSIT_FUNDS_START',
  DEPOSIT_FUNDS_SUCCESS = 'DEPOSIT_FUNDS_SUCCESS',
  DEPOSIT_FUNDS_ERROR = 'DEPOSIT_FUNDS_ERROR',

  SEND_PAYMENT_START = 'SEND_PAYMENT_START',
  SEND_PAYMENT_SUCCESS = 'SEND_PAYMENT_SUCCESS',
  SEND_PAYMENT_ERROR = 'SEND_PAYMENT_ERROR',
  LOGOUT = 'LOGOUT',
  RELOAD = 'persist/REHYDRATE',
}

export type TransactionActionPayload =
  | IDepositPayload
  | ISendPaymentPayload
  | Array<ITransaction>
  | Array<IContact>
  | number
  | string
  | Error
  | null;

export type TransactionAction = IStoreAction<TransactionActionTypes, TransactionActionPayload>;

export class TransactionActions {
  public static getTransactionsStart = (accountNumber: string): TransactionAction => ({
    type: TransactionActionTypes.GET_TRANSACTIONS_START,
    data: accountNumber,
  });
  public static getTransactionsSuccess = (data: Array<ITransaction>): TransactionAction => ({
    type: TransactionActionTypes.GET_TRANSACTIONS_SUCCESS,
    data,
  });
  public static getTransactionsError = (data: Error): TransactionAction => ({
    type: TransactionActionTypes.GET_TRANSACTIONS_ERROR,
    data,
  });
  public static getBalanceStart = (accountNumber: string): TransactionAction => ({
    type: TransactionActionTypes.GET_BALANCE_START,
    data: accountNumber,
  });
  public static getBalanceSuccess = (data: number): TransactionAction => ({
    type: TransactionActionTypes.GET_BALANCE_SUCCESS,
    data,
  });
  public static getBalanceError = (data: Error): TransactionAction => ({
    type: TransactionActionTypes.GET_BALANCE_ERROR,
    data,
  });

  public static getContactsStart = (userId: string): TransactionAction => ({
    type: TransactionActionTypes.GET_CONTACTS_START,
    data: userId,
  });
  public static getContactsSuccess = (data: Array<IContact>): TransactionAction => ({
    type: TransactionActionTypes.GET_CONTACTS_SUCCESS,
    data,
  });
  public static getContactsError = (data: Error): TransactionAction => ({
    type: TransactionActionTypes.GET_CONTACTS_ERROR,
    data,
  });

  public static depositFundsStart = (data: IDepositPayload): TransactionAction => ({
    type: TransactionActionTypes.DEPOSIT_FUNDS_START,
    data,
  });
  public static depositFundsSuccess = (data: string): TransactionAction => ({
    type: TransactionActionTypes.DEPOSIT_FUNDS_SUCCESS,
    data,
  });
  public static depositFundsError = (data: Error): TransactionAction => ({
    type: TransactionActionTypes.DEPOSIT_FUNDS_ERROR,
    data,
  });

  public static sendPaymentStart = (data: ISendPaymentPayload): TransactionAction => ({
    type: TransactionActionTypes.SEND_PAYMENT_START,
    data,
  });
  public static sendPaymentSuccess = (data: string): TransactionAction => ({
    type: TransactionActionTypes.SEND_PAYMENT_SUCCESS,
    data,
  });
  public static sendPaymentError = (data: Error): TransactionAction => ({
    type: TransactionActionTypes.SEND_PAYMENT_ERROR,
    data,
  });
}
