import React, { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TagIcon from '@mui/icons-material/Tag';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LabelIcon from '@mui/icons-material/Label';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { checkObjectFilled } from '../../libs/helper/HelperFunctions';
import './Modal.less';
import { ModalLabels } from '../../theme/ThemeLabels';
import { IContact, IDepositPayload } from '../../libs/models/TransactionModels';
import ModalActions from './ModalActions';

export interface IDepositModalContentProps {
  userAccountNumber: string;
  userList: Array<IContact>;
  onOkClick: (depositPayload: IDepositPayload) => void;
}
const DepositModalContent: React.FC<IDepositModalContentProps> = ({
  userAccountNumber,
  userList,
  onOkClick,
}) => {
  const [selectedUser, setSelectedUser] = useState<string>(
    userList.length ? userList[0].accountNumber : ModalLabels.newExternalAccount,
  );
  const [depositPayload, setDepositPayload] = useState<IDepositPayload>(
    userList.length
      ? {
          userAccountNumber: userAccountNumber,
          accountNumber: userList[0].accountNumber,
          amount: 0,
          newContact: false,
          contactLabel: userList[0].label,
          routingNumber: userList[0].routingNum,
        }
      : {
          userAccountNumber: userAccountNumber,
          accountNumber: '',
          amount: 0,
          newContact: true,
          contactLabel: '',
          routingNumber: '',
        },
  );

  const onCancelClick = () => {
    setDepositPayload({
      userAccountNumber: userAccountNumber,
      accountNumber: '',
      amount: 0,
      newContact: false,
      contactLabel: '',
      routingNumber: '',
    });
  };

  return (
    <>
      <DialogContent>
        <Grid container rowGap={3} className="deposit-modal">
          <span className="modal-item-label">{ModalLabels.externalAccount}</span>
          <Select
            fullWidth
            value={selectedUser}
            onChange={(e) => {
              setSelectedUser(e.target.value);
              if (e.target.value !== ModalLabels.newExternalAccount) {
                const selected = userList.find((user) => user.accountNumber === e.target.value)!;
                setDepositPayload((prevValue) => ({
                  ...prevValue,
                  newContact: false,
                  accountNumber: selected.accountNumber,
                  routingNumber: selected.routingNum,
                  contactLabel: selected.label,
                }));
              } else {
                setDepositPayload((prevValue) => ({
                  ...prevValue,
                  newContact: true,
                  accountNumber: '',
                  routingNumber: '',
                  contactLabel: '',
                }));
              }
            }}
          >
            {userList.map((user) => (
              <MenuItem key={user.accountNumber} value={user.accountNumber}>
                {`${user.label} - ${user.accountNumber} - ${user.routingNum}`}
              </MenuItem>
            ))}
            <hr />
            <MenuItem value={ModalLabels.newExternalAccount}>
              {ModalLabels.newExternalAccount}
            </MenuItem>
          </Select>
          {selectedUser === ModalLabels.newExternalAccount && (
            <Grid container rowGap={3}>
              <Grid item xs={1}>
                <TagIcon className="modal-label-icon" />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={ModalLabels.accountNumber}
                  onChange={(e) => {
                    const changedValue = Number(e.target.value);
                    if (changedValue >= 0) {
                      setDepositPayload((prevValue) => ({
                        ...prevValue,
                        accountNumber: e.target.value,
                      }));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <AccountBalanceIcon className="modal-label-icon" />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={ModalLabels.routingNumber}
                  onChange={(e) =>
                    setDepositPayload((prevValue) => ({
                      ...prevValue,
                      routingNumber: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <LabelIcon className="modal-label-icon" />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={ModalLabels.accountLabel}
                  onChange={(e) =>
                    setDepositPayload((prevValue) => ({
                      ...prevValue,
                      contactLabel: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className="modal-item-label">
            {ModalLabels.depositAmount}
          </Grid>
          <Grid item xs={1}>
            <AttachMoneyIcon className="modal-label-icon" />
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              placeholder={ModalLabels.zeroAmount}
              onChange={(e) =>
                setDepositPayload((prevValue) => ({
                  ...prevValue,
                  amount: Number(e.target.value),
                }))
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ModalActions
          okDisabled={!checkObjectFilled(depositPayload)}
          okText={ModalLabels.deposit}
          cancelText={ModalLabels.cancel}
          onCancelClick={onCancelClick}
          onOkClick={() => onOkClick(depositPayload)}
        />
      </DialogActions>
    </>
  );
};

export default DepositModalContent;
