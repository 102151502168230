import { put, takeEvery } from 'redux-saga/effects';
import { GenAIActionTypes, GenAIActions } from '../actions/GenAIActions';
import {
  IChatSessionPayload,
  ICreateSessionPayload,
  ICreateSessionResponse,
  IGenAISessionDetail,
  IGenAISearchHistory,
  IAutoCompleteSearchPayload,
  AutoCompleteSearchResponse,
  IArchiveChatPayload,
  IUploadGenAiDocumentPayload,
} from '../../models/GenAIModels';
import genAIService from '../../services/GenAIService/GenAIService';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../models/ToastModels';

export function* fetchRecentSearchListWorker(action: { type: string; data: IChatSessionPayload }) {
  try {
    const response: Array<IGenAISearchHistory> = yield genAIService.fetchRecentSearchListApi(
      action.data,
    );
    yield put(GenAIActions.fetchRecentSearchListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('There are no records of AI chat session for this user.')) {
        yield put(
          ToastActions.showToast({
            message: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      } else {
        yield put(
          ToastActions.showToast({
            message: 'Something went wrong. Please try again later...',
            type: NotificationTypes.ERROR,
          }),
        );
      }
      yield put(GenAIActions.fetchRecentSearchListFailure(error));
    }
  }
}

export function* fetchSessionDetailWorker(action: { type: string; payload: { userId: string } }) {
  try {
    const response: Array<IGenAISessionDetail> = yield genAIService.fetchSessionDetailApi(
      action.payload as IChatSessionPayload,
    );
    yield put(GenAIActions.fetchSessionDetailSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('There are no records of AI chat session for this user.')) {
        yield put(
          ToastActions.showToast({
            message: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      } else {
        yield put(
          ToastActions.showToast({
            message: 'Something went wrong. Please try again later...',
            type: NotificationTypes.ERROR,
          }),
        );
      }
      yield put(GenAIActions.fetchSessionDetailFailure(error));
    }
  }
}

export function* currentChatSessionWorker(action: { type: string; data: ICreateSessionPayload }) {
  try {
    const response: ICreateSessionResponse = yield genAIService.fetchCurrentChatSession(
      action.data,
    );
    yield put(GenAIActions.fetchRecentSearchListStart({ userId: action.data.userId }));
    yield put(GenAIActions.currentChatSessionSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      if (error.message.includes('Some error occurred while creating AI chat session.')) {
        yield put(
          ToastActions.showToast({
            message: error.message,
            type: NotificationTypes.ERROR,
          }),
        );
      } else {
        yield put(
          ToastActions.showToast({
            message: 'Something went wrong. Please try again later...',
            type: NotificationTypes.ERROR,
          }),
        );
      }
      yield put(GenAIActions.currentChatSessionFailure(error));
    }
  }
}

export function* autoCompleteOptionsWorker(action: {
  type: string;
  data: IAutoCompleteSearchPayload;
}) {
  try {
    const response: AutoCompleteSearchResponse = yield genAIService.fetchAutoCompleteOptions(
      action.data as IAutoCompleteSearchPayload,
    );
    yield put(GenAIActions.getAutoCompleteOptionsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          message: 'Failed to fetch the suggestions.',
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(GenAIActions.getAutoCompleteOptionsFailure(error));
    }
  }
}

export function* archiveChatWorker(action: { type: GenAIActionTypes; data: IArchiveChatPayload }) {
  try {
    const response: string = yield genAIService.archiveChat(action.data);
    yield put(GenAIActions.archiveChatSuccess());
    yield put(
      ToastActions.showToast({
        message: action.data.isDeleted ? 'Deleted successfully' : response,
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(GenAIActions.fetchRecentSearchListStart({ userId: action.data.userId }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          message: action.data.isDeleted ? 'Failed to delete chat' : error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(GenAIActions.archiveChatFailure());
    }
  }
}

export function* uploadDocumentWorker(action: {
  type: GenAIActionTypes;
  data: IUploadGenAiDocumentPayload;
}) {
  try {
    yield genAIService.uploadDocument(action.data);
    yield put(GenAIActions.uploadDocumentSuccess());
    yield put(
      ToastActions.showToast({
        message: `${action.data.fileName} uploaded successfully`,
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(GenAIActions.fetchRecentSearchListStart({ userId: action.data.userId }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(GenAIActions.uploadDocumentFailure());
    }
  }
}

export function* hardReloadWorker() {
  yield put(GenAIActions.handleHardReload());
}

export function* genAIWatcher() {
  yield takeEvery(GenAIActionTypes.FETCH_CHAT_SESSION_LIST_REQUEST, fetchRecentSearchListWorker);
  yield takeEvery(GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_START, fetchSessionDetailWorker);
  yield takeEvery(GenAIActionTypes.POST_SESSION_START, currentChatSessionWorker);
  yield takeEvery(GenAIActionTypes.FETCH_AUTO_COMPLETE_START, autoCompleteOptionsWorker);
  yield takeEvery(GenAIActionTypes.ARCHIVE_CHAT_START, archiveChatWorker);
  yield takeEvery(GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_START, uploadDocumentWorker);
  yield takeEvery('persist/REHYDRATE', hardReloadWorker);
}
