import {
  IChatSessionPayload,
  ICreateSessionPayload,
  ICreateSessionResponse,
  IGenAISessionDetail,
  IGenAISearchHistory,
  AutoCompleteSearchResponse,
  IAutoCompleteSearchPayload,
  IArchiveChatPayload,
  IUploadGenAiDocumentPayload,
} from '../../models/GenAIModels';
import { IStoreAction } from '../StoreHelper';

export enum GenAIActionTypes {
  FETCH_CHAT_SESSION_LIST_REQUEST = 'FETCH_CHAT_SESSION_LIST_REQUEST',
  FETCH_CHAT_SESSION_LIST_SUCCESS = 'FETCH_CHAT_SESSION_LIST_SUCCESS',
  FETCH_CHAT_SESSION_LIST_ERROR = 'FETCH_CHAT_SESSION_LIST_ERROR',

  FETCH_CHAT_SESSION_DETAIL_START = 'FETCH_CHAT_SESSION_DETAIL_START',
  FETCH_CHAT_SESSION_DETAIL_SUCCESS = 'FETCH_CHAT_SESSION_DETAIL_SUCCESS',
  FETCH_CHAT_SESSION_DETAIL_ERROR = 'FETCH_CHAT_SESSION_DETAIL_ERROR',

  FETCH_AUTO_COMPLETE_START = 'FETCH_AUTO_COMPLETE_START',
  FETCH_AUTO_COMPLETE_SUCCESS = 'FETCH_AUTO_COMPLETE_SUCCESS',
  FETCH_AUTO_COMPLETE_ERROR = 'FETCH_AUTO_COMPLETE_ERROR',

  POST_SESSION_START = 'POST_SESSION_START',
  POST_SESSION_SUCCESS = 'POST_SESSION_SUCCESS',
  POST_SESSION_ERROR = 'POST_SESSION_ERROR',

  ARCHIVE_CHAT_START = 'ARCHIVE_CHAT_START',
  ARCHIVE_CHAT_SUCCESS = 'ARCHIVE_CHAT_SUCCESS',
  ARCHIVE_CHAT_ERROR = 'ARCHIVE_CHAT_ERROR',

  UPLOAD_GEN_AI_DOCUMENT_START = 'UPLOAD_GEN_AI_DOCUMENT_START',
  UPLOAD_GEN_AI_DOCUMENT_SUCCESS = 'UPLOAD_GEN_AI_DOCUMENT_SUCCESS',
  UPLOAD_GEN_AI_DOCUMENT_ERROR = 'UPLOAD_GEN_AI_DOCUMENT_ERROR',

  GEN_AI_HARD_RELOAD = 'GEN_AI_HARD_RELOAD',
}

export type GenAIActionPayload =
  | IChatSessionPayload
  | Array<IGenAISearchHistory>
  | Array<IGenAISessionDetail>
  | ICreateSessionResponse
  | ICreateSessionPayload
  | AutoCompleteSearchResponse
  | IAutoCompleteSearchPayload
  | IArchiveChatPayload
  | IUploadGenAiDocumentPayload
  | string
  | Error
  | null;

export type GenAIAction = IStoreAction<GenAIActionTypes, GenAIActionPayload>;

export class GenAIActions {
  public static fetchRecentSearchListStart = (data: IChatSessionPayload): GenAIAction => ({
    type: GenAIActionTypes.FETCH_CHAT_SESSION_LIST_REQUEST,
    data,
  });
  public static fetchRecentSearchListSuccess = (data: Array<IGenAISearchHistory>): GenAIAction => ({
    type: GenAIActionTypes.FETCH_CHAT_SESSION_LIST_SUCCESS,
    data,
  });
  public static fetchRecentSearchListFailure = (data: Error): GenAIAction => ({
    type: GenAIActionTypes.FETCH_CHAT_SESSION_LIST_ERROR,
    data,
  });

  public static fetchSessionDetailRequest = (data: IChatSessionPayload): GenAIAction => ({
    type: GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_START,
    data,
  });
  public static fetchSessionDetailSuccess = (data: Array<IGenAISessionDetail>): GenAIAction => ({
    type: GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_SUCCESS,
    data,
  });
  public static fetchSessionDetailFailure = (data: Error): GenAIAction => ({
    type: GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_ERROR,
    data,
  });

  public static currentChatSessionStart = (data: ICreateSessionPayload): GenAIAction => ({
    type: GenAIActionTypes.POST_SESSION_START,
    data,
  });
  public static currentChatSessionSuccess = (data: ICreateSessionResponse): GenAIAction => ({
    type: GenAIActionTypes.POST_SESSION_SUCCESS,
    data,
  });
  public static currentChatSessionFailure = (data: Error): GenAIAction => ({
    type: GenAIActionTypes.POST_SESSION_ERROR,
    data,
  });

  public static getAutoCompleteOptionsStart = (data: IAutoCompleteSearchPayload): GenAIAction => ({
    type: GenAIActionTypes.FETCH_AUTO_COMPLETE_START,
    data,
  });
  public static getAutoCompleteOptionsSuccess = (
    data: AutoCompleteSearchResponse,
  ): GenAIAction => ({
    type: GenAIActionTypes.FETCH_AUTO_COMPLETE_SUCCESS,
    data,
  });
  public static getAutoCompleteOptionsFailure = (data: Error): GenAIAction => ({
    type: GenAIActionTypes.FETCH_AUTO_COMPLETE_ERROR,
    data,
  });

  public static archiveChatStart = (data: IArchiveChatPayload): GenAIAction => ({
    type: GenAIActionTypes.ARCHIVE_CHAT_START,
    data,
  });
  public static archiveChatSuccess = (): GenAIAction => ({
    type: GenAIActionTypes.ARCHIVE_CHAT_SUCCESS,
  });
  public static archiveChatFailure = (): GenAIAction => ({
    type: GenAIActionTypes.ARCHIVE_CHAT_ERROR,
  });

  public static uploadDocumentStart = (data: IUploadGenAiDocumentPayload): GenAIAction => ({
    type: GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_START,
    data,
  });
  public static uploadDocumentSuccess = (): GenAIAction => ({
    type: GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_SUCCESS,
  });
  public static uploadDocumentFailure = (): GenAIAction => ({
    type: GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_ERROR,
  });
  public static handleHardReload = (): GenAIAction => ({
    type: GenAIActionTypes.GEN_AI_HARD_RELOAD,
  });
}
