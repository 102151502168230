import { IQueryResponse } from '../../models/ChatbotModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class ChatbotService {
  private readonly ChatbotService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.chatbot,
  );

  public getQueryResponse(payload: string): Promise<IQueryResponse> | IQueryResponse {
    const route: string[] = [API_ROUTE.CHATBOT];
    return this.ChatbotService.post({ route: route }, { query: payload });
  }
}

const chatbotService = new ChatbotService();
export default chatbotService;
