import React, { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TagIcon from '@mui/icons-material/Tag';
import LabelIcon from '@mui/icons-material/Label';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { checkObjectFilled } from '../../libs/helper/HelperFunctions';
import './Modal.less';
import { ModalLabels } from '../../theme/ThemeLabels';
import { IContact, ISendPaymentPayload } from '../../libs/models/TransactionModels';
import ModalActions from './ModalActions';

export interface ISendPaymentModalContentProps {
  userAccountNumber: string;
  userList: Array<IContact>;
  onOkClick: (sendPaymentPayload: ISendPaymentPayload) => void;
}
const SendPaymentModalContent: React.FC<ISendPaymentModalContentProps> = ({
  userAccountNumber,
  userList,
  onOkClick,
}) => {
  const [selectedUser, setSelectedUser] = useState<string>(
    userList.length ? userList[0].accountNumber : ModalLabels.newRecipient,
  );

  const [sendPaymentPayload, setSendPaymentPayload] = useState<ISendPaymentPayload>(
    userList.length
      ? {
          userAccountNumber: userAccountNumber,
          accountNumber: userList[0].accountNumber,
          amount: 0,
          newContact: false,
          contactLabel: userList[0].label,
        }
      : {
          userAccountNumber: userAccountNumber,
          accountNumber: '',
          amount: 0,
          newContact: true,
          contactLabel: '',
        },
  );

  const onCancelClick = () => {
    setSendPaymentPayload({
      userAccountNumber: userAccountNumber,
      accountNumber: '',
      amount: 0,
      newContact: false,
      contactLabel: '',
    });
  };

  return (
    <>
      <DialogContent>
        <Grid container rowGap={3} className="deposit-modal">
          <span className="modal-item-label">{ModalLabels.recipient}</span>
          <Select
            fullWidth
            value={selectedUser}
            onChange={(e) => {
              setSelectedUser(e.target.value);
              if (e.target.value !== ModalLabels.newRecipient) {
                const selected = userList.find((user) => user.accountNumber === e.target.value)!;
                setSendPaymentPayload((prevValue) => ({
                  ...prevValue,
                  newContact: false,
                  accountNumber: selected.accountNumber,
                  contactLabel: selected.label,
                }));
              } else {
                setSendPaymentPayload((prevValue) => ({
                  ...prevValue,
                  newContact: true,
                  accountNumber: '',
                  contactLabel: '',
                }));
              }
            }}
          >
            {userList.map((user) => (
              <MenuItem key={user.accountNumber} value={user.accountNumber}>
                {`${user.label} - ${user.accountNumber}`}
              </MenuItem>
            ))}
            <hr />
            <MenuItem value={ModalLabels.newRecipient}>{ModalLabels.newRecipient}</MenuItem>
          </Select>
          {selectedUser === ModalLabels.newRecipient && (
            <Grid container rowGap={3}>
              <Grid item xs={1}>
                <TagIcon className="modal-label-icon" />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={ModalLabels.accountNumber}
                  onChange={(e) =>
                    setSendPaymentPayload((prevValue) => ({
                      ...prevValue,
                      accountNumber: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <LabelIcon className="modal-label-icon" />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder={ModalLabels.accountLabel}
                  onChange={(e) =>
                    setSendPaymentPayload((prevValue) => ({
                      ...prevValue,
                      contactLabel: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className="modal-item-label">
            {ModalLabels.transactionAmount}
          </Grid>
          <Grid item xs={1}>
            <AttachMoneyIcon className="modal-label-icon" />
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              placeholder={ModalLabels.zeroAmount}
              onChange={(e) => {
                const changedValue = Number(e.target.value);
                if (changedValue >= 0) {
                  setSendPaymentPayload((prevValue) => ({
                    ...prevValue,
                    amount: Number(e.target.value),
                  }));
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ModalActions
          okDisabled={!checkObjectFilled(sendPaymentPayload)}
          okText={ModalLabels.send}
          cancelText={ModalLabels.cancel}
          onCancelClick={onCancelClick}
          onOkClick={() => onOkClick(sendPaymentPayload)}
        />
      </DialogActions>
    </>
  );
};

export default SendPaymentModalContent;
