import { IQueryResponse } from '../../models/ChatbotModels';
import { IStoreAction } from '../StoreHelper';

export enum ChatbotActionTypes {
  GET_CHAT_RESPONSE_START = 'GET_CHAT_RESPONSE_START',
  GET_CHAT_RESPONSE_SUCCESS = 'GET_CHAT_RESPONSE_SUCCESS',
  GET_CHAT_RESPONSE_ERROR = 'GET_CHAT_RESPONSE_ERROR',
  CLEAR_RESPONSE = 'CLEAR_RESPONSE',
  RELOAD = 'persist/REHYDRATE',
}

export type ChatbotActionPayload = string | IQueryResponse | Error | null;

export type ChatbotAction = IStoreAction<ChatbotActionTypes, ChatbotActionPayload>;

export class ChatbotActions {
  public static getChatResponseStart = (data: string): ChatbotAction => ({
    type: ChatbotActionTypes.GET_CHAT_RESPONSE_START,
    data,
  });
  public static getChatResponseSuccess = (data: IQueryResponse): ChatbotAction => ({
    type: ChatbotActionTypes.GET_CHAT_RESPONSE_SUCCESS,
    data,
  });
  public static getChatResponseError = (data: Error): ChatbotAction => ({
    type: ChatbotActionTypes.GET_CHAT_RESPONSE_ERROR,
    data,
  });
  public static clearResponse = (): ChatbotAction => ({
    type: ChatbotActionTypes.CLEAR_RESPONSE,
  });
}
