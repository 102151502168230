import { Reducer } from 'redux';
import { ToastAction, ToastActionTypes } from '../actions/ToastActions';
import ToastState from '../state/ToastState';
import { IShowToastPayload } from '../../models/ToastModels';

export const initialState: ToastState = {
  error: '',
  loading: false,
  toastList: [],
};

export const toastReducer: Reducer<ToastState> = (state = initialState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionTypes.SHOW_TOAST:
      return {
        ...state,
        toastList: [...state.toastList, action.data as IShowToastPayload],
      };

    case ToastActionTypes.HIDE_TOAST:
      return {
        ...state,
        toastList: [...state.toastList.slice(1)],
      };

    default:
      return state;
  }
};
