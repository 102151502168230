import { Reducer } from 'redux';
import ILoginState from '../state/LoginState';
import { LoginAction, LoginActionTypes } from '../actions/LoginActions';
import { ILoginResponse } from '../../models/LoginModels';

export const initialState: ILoginState = {
  user: '',
  accountNumber: '',
  name: '',
  expiry: 0,
  error: undefined,
  loading: false,
};

export const loginReducer: Reducer<ILoginState> = (state = initialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_START:
      return { ...state, loading: true };

    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: (action.data as ILoginResponse).user,
        name: (action.data as ILoginResponse).name,
        accountNumber: (action.data as ILoginResponse).acct,
        expiry: (action.data as ILoginResponse).exp,
      };
    case LoginActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case LoginActionTypes.LOGOUT:
      return {
        ...state,
        user: '',
        accountNumber: '',
        name: '',
        expiry: 0,
      };
    default:
      return state;
  }
};
