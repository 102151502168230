import { put, takeEvery } from 'redux-saga/effects';
import { LoginActionTypes, LoginActions } from '../actions/LoginActions';
import { ILoginPayload } from '../../models/LoginModels';
import loginService from '../../services/LoginService/LoginService';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../models/ToastModels';
import { storage, STORAGE_CONSTANTS } from '../../services/LocalStorage';

export function* loginWorker(
  action: { type: LoginActionTypes; data: ILoginPayload },
  retryAttempt = 0,
): any {
  try {
    const response: { token: string } = yield loginService.loginUser(action.data);
    storage.setItem(STORAGE_CONSTANTS.accessToken, response.token);
    const object = JSON.parse(atob(response.token.split('.')[1]));
    yield put(LoginActions.loginSuccess(object));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(LoginActions.loginError(error));
    } else {
      yield loginWorker(action, retryAttempt + 1);
    }
  }
}

export function* hardReloadWorker() {
  const token = storage.getItem(STORAGE_CONSTANTS.accessToken);
  if (token) {
    const object = JSON.parse(atob(token.split('.')[1]));
    yield put(LoginActions.loginSuccess(object));
  }
}

export function* loginWatcher() {
  yield takeEvery(LoginActionTypes.LOGIN_START, loginWorker);
  yield takeEvery('persist/REHYDRATE', hardReloadWorker);
}
