import { put, takeEvery } from 'redux-saga/effects';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../models/ToastModels';
import { TransactionActionTypes, TransactionActions } from '../actions/TransactionActions';
import transactionService from '../../services/TransactionService/TransactionService';
import {
  IContact,
  IContactResponse,
  IDepositPayload,
  ISendPaymentPayload,
  ITransaction,
} from '../../models/TransactionModels';
import { getCurrencyFormat } from '../../helper/HelperFunctions';

export function* getTransactionsWorker(
  action: { type: TransactionActionTypes; data: string },
  retryAttempt = 0,
): any {
  try {
    const response: Array<ITransaction> = yield transactionService.getTransactions(action.data);
    yield put(TransactionActions.getTransactionsSuccess(response));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(TransactionActions.getTransactionsError(error));
    } else {
      yield getTransactionsWorker(action, retryAttempt + 1);
    }
  }
}
export function* getBalanceWorker(
  action: { type: TransactionActionTypes; data: string },
  retryAttempt = 0,
): any {
  try {
    const response: number = yield transactionService.getBalance(action.data);
    yield put(TransactionActions.getBalanceSuccess(response));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(TransactionActions.getBalanceError(error));
    } else {
      yield getBalanceWorker(action, retryAttempt + 1);
    }
  }
}
export function* getContactsWorker(
  action: { type: TransactionActionTypes; data: string },
  retryAttempt = 0,
): any {
  try {
    const response: Array<IContactResponse> = yield transactionService.getContacts(action.data);
    yield put(
      TransactionActions.getContactsSuccess(
        response.map(
          ({ account_num, is_external, label, routing_num }): IContact => ({
            accountNumber: account_num,
            isExternal: is_external,
            label: label,
            routingNum: routing_num,
          }),
        ),
      ),
    );
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(TransactionActions.getContactsError(error));
    } else {
      yield getContactsWorker(action, retryAttempt + 1);
    }
  }
}

export function* depositFundsWorker(
  action: {
    type: TransactionActionTypes;
    data: IDepositPayload;
  },
  retryAttempt = 0,
): any {
  try {
    const response: string = yield transactionService.depositAmount(action.data);
    yield put(TransactionActions.depositFundsSuccess(response));
    yield put(
      ToastActions.showToast({
        message: `Deposit of ${getCurrencyFormat(action.data.amount)} from ${
          action.data.contactLabel
        } is successful`,
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(TransactionActions.getBalanceStart(action.data.userAccountNumber));
    yield put(TransactionActions.getTransactionsStart(action.data.userAccountNumber));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(TransactionActions.depositFundsError(error));
    } else {
      yield depositFundsWorker(action, retryAttempt + 1);
    }
  }
}

export function* sendPaymentWorker(
  action: {
    type: TransactionActionTypes;
    data: ISendPaymentPayload;
  },
  retryAttempt = 0,
): any {
  try {
    const response: string = yield transactionService.sendPayment(action.data);
    yield put(TransactionActions.sendPaymentSuccess(response));
    yield put(
      ToastActions.showToast({
        message: `Payment of ${getCurrencyFormat(action.data.amount)} to ${
          action.data.contactLabel
        } is successful`,
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(TransactionActions.getBalanceStart(action.data.userAccountNumber));
    yield put(TransactionActions.getTransactionsStart(action.data.userAccountNumber));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(TransactionActions.sendPaymentError(error));
    } else {
      yield sendPaymentWorker(action, retryAttempt + 1);
    }
  }
}

export function* transactionWatcher() {
  yield takeEvery(TransactionActionTypes.GET_TRANSACTIONS_START, getTransactionsWorker);
  yield takeEvery(TransactionActionTypes.GET_BALANCE_START, getBalanceWorker);
  yield takeEvery(TransactionActionTypes.GET_CONTACTS_START, getContactsWorker);
  yield takeEvery(TransactionActionTypes.DEPOSIT_FUNDS_START, depositFundsWorker);
  yield takeEvery(TransactionActionTypes.SEND_PAYMENT_START, sendPaymentWorker);
}
