import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import './Modal.less';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { ModalTypes } from '../../AppConstants';
import { CommonActions } from '../../libs/store/actions/CommonActions';
export interface IModalProps {
  theme: string;
}

const Modal: React.FC<IModalProps> = ({ theme }) => {
  const dispatch = useDispatch();

  const dispatcher = {
    closeModal: () => {
      return dispatch(CommonActions.modalClose());
    },
  };

  const { modalType, header, content } = useAppSelector(
    ({
      common: {
        modal: { modalType, header, content },
      },
    }: IApplicationState) => ({
      modalType,
      header,
      content,
    }),
  );
  return (
    <Dialog
      open={modalType !== ModalTypes.NONE}
      maxWidth={'xs'}
      onClose={() => dispatcher.closeModal()}
      className={`modal-container ${theme}`}
    >
      <DialogTitle>{header}</DialogTitle>
      {content}
    </Dialog>
  );
};

export default Modal;
