import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import './Modal.less';
import { CommonActions } from '../../libs/store/actions/CommonActions';
export interface IModalActionsProps {
  okText: string;
  cancelText: string;
  okDisabled: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
}

const ModalActions: React.FC<IModalActionsProps> = ({
  okText,
  cancelText,
  okDisabled,
  onOkClick,
  onCancelClick,
}) => {
  const dispatch = useDispatch();

  const dispatcher = {
    closeModal: () => {
      return dispatch(CommonActions.modalClose());
    },
  };

  const handleAction = (method: () => void) => {
    method();
    dispatcher.closeModal();
  };

  return (
    <>
      <Button className="modal-cancel-button" onClick={() => handleAction(onCancelClick)}>
        {cancelText}
      </Button>
      <Button
        className="modal-ok-button"
        disabled={okDisabled}
        onClick={() => handleAction(onOkClick)}
      >
        {okText}
      </Button>
    </>
  );
};

export default ModalActions;
