import { put, takeEvery } from 'redux-saga/effects';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../models/ToastModels';
import { ChatbotActions, ChatbotActionTypes } from '../actions/ChatbotActions';
import chatbotService from '../../services/ChatbotService/ChatbotService';
import { IQueryResponse } from '../../models/ChatbotModels';

export function* getChatResponseWorker(
  action: { type: ChatbotActionTypes; data: string },
  retryAttempt = 0,
): any {
  try {
    const response: IQueryResponse = yield chatbotService.getQueryResponse(action.data);
    yield put(ChatbotActions.getChatResponseSuccess(response));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(ChatbotActions.getChatResponseError(error));
    } else {
      yield getChatResponseWorker(action, retryAttempt + 1);
    }
  }
}

export function* chatbotWatcher() {
  yield takeEvery(ChatbotActionTypes.GET_CHAT_RESPONSE_START, getChatResponseWorker);
}
