import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DescriptionIcon from '@mui/icons-material/Description';
import DraftsIcon from '@mui/icons-material/Drafts';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useDispatch } from 'react-redux';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './Transactions.less';
import { TransactionsLabels, ModalLabels, ApplicationLabels } from '../../theme/ThemeLabels';
import { getCurrencyFormat, getTimestampString } from '../../libs/helper/HelperFunctions';
import { TransactionActions } from '../../libs/store/actions/TransactionActions';
import { CommonActions } from '../../libs/store/actions/CommonActions';
import { IOpenModalPayload } from '../../libs/models/CommonModels';
import { ModalTypes } from '../../AppConstants';
import DepositModalContent from '../Modal/DepositModalContent';
import { IDepositPayload, ISendPaymentPayload } from '../../libs/models/TransactionModels';
import SendPaymentModalContent from '../Modal/SendPaymentModalContent';

interface ITransactionsProps {
  selectedAccount?: string;
  setSelectedAccount: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Transactions: React.FC<ITransactionsProps> = ({ selectedAccount, setSelectedAccount }) => {
  const [searchText, setSearchText] = useState<string>('');

  const { transactions, accountNumber, userId, balance, contacts } = useAppSelector(
    ({
      transaction: { transactions, balance, contacts },
      login: { accountNumber, user },
    }: IApplicationState) => ({
      transactions,
      accountNumber,
      userId: user,
      balance,
      contacts,
    }),
  );
  const dispatch = useDispatch();

  const dispatcher = {
    getTransactions: () => {
      return dispatch(TransactionActions.getTransactionsStart(accountNumber));
    },
    getBalance: () => {
      return dispatch(TransactionActions.getBalanceStart(accountNumber));
    },
    getContacts: () => {
      return dispatch(TransactionActions.getContactsStart(userId));
    },
    openModal: (data: IOpenModalPayload) => {
      return dispatch(CommonActions.modalOpen(data));
    },
    depositFunds: (depositPayload: IDepositPayload) => {
      return dispatch(TransactionActions.depositFundsStart(depositPayload));
    },
    sendPayment: (sendPaymentPayload: ISendPaymentPayload) => {
      return dispatch(TransactionActions.sendPaymentStart(sendPaymentPayload));
    },
  };

  // TODO: Replace the below useeffect with this once we have multiaccount
  // useEffect(() => {
  //   if (!isEmpty(selectedAccount)) {
  //     dispatcher.getTransactions();
  //     dispatcher.getBalance();
  //     dispatcher.getContacts();
  //   }
  // }, [selectedAccount]);

  useEffect(() => {
    dispatcher.getTransactions();
    dispatcher.getBalance();
    dispatcher.getContacts();
  }, []);

  const renderTransactions = () => {
    let currDate = '';
    let currBalance = balance;
    return transactions.reduce<Array<React.ReactNode>>((prevValue, transaction, index) => {
      let currValue = [...prevValue];
      const transactionDate = getTimestampString(transaction.timestamp);
      const isDebit = transaction.fromAccountNum === accountNumber;
      const transactionType = isDebit ? TransactionsLabels.debit : TransactionsLabels.credit;
      const transactionLabel =
        transaction.label ||
        contacts
          .filter((contact) => contact.isExternal !== isDebit)
          .find(
            (contact) =>
              contact.accountNumber ===
              (isDebit ? transaction.toAccountNum : transaction.fromAccountNum),
          )?.label ||
        'NA';
      const transactionBalance = currBalance;
      if (isDebit) {
        currBalance += transaction.amount;
      } else {
        currBalance -= transaction.amount;
      }

      if (
        searchText !== '' &&
        !`${JSON.stringify(
          transaction,
        )} ${transactionDate} ${transactionType} ${transactionLabel} ${transactionBalance}`.toLowerCase().includes(
          searchText.toLowerCase(),
        )
      ) {
        return prevValue;
      }

      if (transactionDate !== currDate) {
        currDate = transactionDate;
        currValue = [
          ...currValue,
          <Grid key={transactionDate} container className="dashborad-transaction-date">
            {transactionDate}
          </Grid>,
        ];
      }
      currValue = [
        ...currValue,
        <Grid
          key={index}
          container
          justifyContent={'space-around'}
          alignItems={'center'}
          className="dashboard-transaction-box"
        >
          <Grid item xs={9} container>
            <span className="dashboard-transaction-currency">{ApplicationLabels.dollar}</span>
            <Grid>
              <Grid className="dashboard-transaction-label">{transactionLabel}</Grid>
              <Grid className="dashboard-transaction-type">{transactionType}</Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={`dashboard-transaction-amount ${transactionType}`}>
            {getCurrencyFormat(transaction.amount)}
          </Grid>
          <Grid item xs={1} className="dashboard-transaction-amount">
            {getCurrencyFormat(transactionBalance)}
          </Grid>
        </Grid>,
      ];
      return currValue;
    }, []);
  };

  const handleDepositClicked = () => {
    dispatcher.openModal({
      modalType: ModalTypes.DEPOSIT_FUNDS,
      header: ModalLabels.depositHeader,
      content: (
        <DepositModalContent
          userAccountNumber={accountNumber}
          userList={contacts.filter((contact) => contact.isExternal)}
          onOkClick={(depositPayload) => {
            dispatcher.depositFunds(depositPayload);
          }}
        />
      ),
    });
  };

  const handleSendClicked = () => {
    dispatcher.openModal({
      modalType: ModalTypes.SEND_PAYMENT,
      header: ModalLabels.sendPaymentHeader,
      content: (
        <SendPaymentModalContent
        userAccountNumber={accountNumber}
          userList={contacts.filter((contact) => !contact.isExternal)}
          onOkClick={(sendPaymentPayload) => {
            dispatcher.sendPayment(sendPaymentPayload);
          }}
        />
      ),
    });
  };

  return (
    <Grid container className="dashboard-content">
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <IconButton
          size="medium"
          aria-label="back"
          color="inherit"
          onClick={() => setSelectedAccount(undefined)}
        >
          <ArrowBackIcon fontSize="large" />
          <span className="dashboard-detail-name">{selectedAccount}</span>
        </IconButton>
      </Grid>
      <Grid container className="dashboard-accounts">
        <Grid container justifyContent={'space-between'} alignItems={'center'} rowGap={2}>
          <Button
            onClick={handleDepositClicked}
            className="dashboard-details-button"
            startIcon={<AddBoxIcon />}
          >
            {TransactionsLabels.transferFunds}
            <span className='dashboard-details-button-subtitle'>{TransactionsLabels.betweenAccounts}</span>
          </Button>
          <Button
            onClick={handleSendClicked}
            className="dashboard-details-button"
            startIcon={<SendIcon />}
          >
            {TransactionsLabels.sendPayment}
          </Button>
          <Button className="dashboard-details-button disabled-click" startIcon={<DescriptionIcon />}>
            {TransactionsLabels.statements}
          </Button>
          <Button className="dashboard-details-button disabled-click" startIcon={<DraftsIcon />}>
            {TransactionsLabels.billPay}
          </Button>
        </Grid>
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          className="dashboard-upcoming-container"
        >
          <span className="dashboard-upcoming-left">
            <ExpandMoreIcon />
            {TransactionsLabels.showUpcoming}
          </span>
          <span className="dashboard-upcoming-right">{TransactionsLabels.noUpcoming}</span>
        </Grid>
        <Grid container justifyContent={'space-between'} className="dashboard-transactions-header">
          <Grid>
            <Grid className="dashboard-transactions-left">
              {TransactionsLabels.transactionHistory}
            </Grid>
            <Grid className="dashboard-transactions-left-subtext">
              {TransactionsLabels.transactionSubtext}
            </Grid>
          </Grid>
          <Grid>
            <span className="dashboard-transactions-right">{TransactionsLabels.download}</span>
            <DownloadIcon className="dashboard-transactions-right-icon" fontSize="medium" />
          </Grid>
        </Grid>
        <Grid container className="dashboard-transactions-container" rowGap={2}>
          <Grid item xs={11}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={TransactionsLabels.searchPlaceholder}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ backgroundColor: 'white' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={1} item container justifyContent={'flex-end'}>
            <IconButton size="medium" aria-label="filter" className="dashboard-transactions-filter">
              <span>{TransactionsLabels.filter}</span>
              <FilterAltIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={9} className="dashboard-transactions-table-header">
              {TransactionsLabels.details}
            </Grid>
            <Grid item xs={1} className="dashboard-transactions-table-header">
              {TransactionsLabels.amount}
            </Grid>
            <Grid
              xs={1}
              item
              className="dashboard-transactions-table-header"
              container
              justifyContent={'flex-start'}
            >
              {TransactionsLabels.balance}
            </Grid>
          </Grid>
          {renderTransactions()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Transactions;
