export enum NotificationTypes {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface IShowToastPayload {
  message: string;
  type: NotificationTypes;
}
