import { ServerType } from './libs/services/ServiceType.data';

export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

const DCAurls: { [key: string]: string } = {
  local: 'https://jmly57rgw1.execute-api.us-east-1.amazonaws.com',
  dev: 'https://jmly57rgw1.execute-api.us-east-1.amazonaws.com',
  prod: 'https://di5gisv68c.execute-api.us-east-1.amazonaws.com',
};

const LoginUrls: { [key: string]: string } = {
  local: 'https://boaapi.dca.deloitte.com/userservice',
  dev: 'https://boaapi.dca.deloitte.com/userservice',
  prod: 'https://boaapi.dca.deloitte.com/userservice',
};
const TransactionUrls: { [key: string]: string } = {
  local: 'https://boaapi.dca.deloitte.com/transactionhistory',
  dev: 'https://boaapi.dca.deloitte.com/transactionhistory',
  prod: 'https://boapi.dca.deloitte.com/transactionhistory',
};

const BalanceUrls: { [key: string]: string } = {
  local: 'https://boaapi.dca.deloitte.com/balancereader',
  dev: 'https://boaapi.dca.deloitte.com/balancereader',
  prod: 'https://boaapi.dca.deloitte.com/balancereader',
};

const ContactsUrls: { [key: string]: string } = {
  local: 'https://boaapi.dca.deloitte.com/contacts',
  dev: 'https://boaapi.dca.deloitte.com/contacts',
  prod: 'https://boaapi.dca.deloitte.com/contacts',
};

const PaymentUrls: { [key: string]: string } = {
  local: 'https://boaapi.dca.deloitte.com/transactionservice',
  dev: 'https://boaapi.dca.deloitte.com/transactionservice',
  prod: 'https://boaapi.dca.deloitte.com/transactionservice',
};

const NFCUUrls: { [key: string]: string } = {
  local: 'https://intg.b2b-api.navyfederal.org/gcp-poc/v1',
  dev: 'https://intg.b2b-api.navyfederal.org/gcp-poc/v1',
  prod: 'https://intg.b2b-api.navyfederal.org/gcp-poc/v1',
};

const ChatbotUrls: { [key: string]: string } = {
  local: 'https://boaapi.dca.deloitte.com/ai-chatbot-service',
  dev: 'https://boaapi.dca.deloitte.com/ai-chatbot-service',
  prod: 'https://boaapi.dca.deloitte.com/ai-chatbot-service',
};

/**
 * Setting BaseUrl
 *
 * If env vars have been set by envconsul it will use that.
 * Otherwise it will look for .env file with the REACT_APP_API_ENV, if not present it will default to localhost
 * */
const targetEnv = process.env.REACT_APP_API_ENV || 'dev';

export const AppSettings = {
  logging: { logLevel: LogLevel.Errors },
};

export const DefaultLoggingLevel = LogLevel.Errors;

export const ServerEndpoints: { [key in ServerType]: string } = {
  [ServerType.dca]: DCAurls[targetEnv],
  [ServerType.login]: process.env.REACT_APP_USER_SERVICE_URL || LoginUrls[targetEnv],
  [ServerType.transaction]:
    process.env.REACT_APP_TRANSACTION_HISTORY_URL || TransactionUrls[targetEnv],
  [ServerType.balance]: process.env.REACT_APP_BALANCE_READER_URL || BalanceUrls[targetEnv],
  [ServerType.contacts]: process.env.REACT_APP_CONTACTS_URL || ContactsUrls[targetEnv],
  [ServerType.payments]: process.env.REACT_APP_TRANSACTION_SERVICE_URL || PaymentUrls[targetEnv],
  [ServerType.nfcu]: process.env.REACT_APP_NFCU_URL || NFCUUrls[targetEnv],
  [ServerType.chatbot]: process.env.REACT_APP_CHATBOT_URL || ChatbotUrls[targetEnv],
};
