import { IGetRelationshipResponse, IGetRelationshipsPayload } from '../../models/NFCUModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class NFCUService {
  private readonly NfcuService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.payments,
  );

  public getRelationships(payload: IGetRelationshipsPayload): Promise<IGetRelationshipResponse> | IGetRelationshipResponse {
    const route: string[] = [API_ROUTE.NFCU, API_ROUTE.ACCOUNTS, API_ROUTE.RELATIONSHIPS];
    return this.NfcuService.get({ route: route });
  }
}

const nfcuService = new NFCUService();
export default nfcuService;
