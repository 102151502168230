import { put, takeEvery } from 'redux-saga/effects';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../models/ToastModels';
import { NFCUActionTypes, NFCUActions } from '../actions/NFCUActions';
import {
  IGetRelationship,
  IGetRelationshipResponse,
  IGetRelationshipsPayload,
} from '../../models/NFCUModels';
import nfcuService from '../../services/NFCUService/NFCUService';

export function* getAccountsRelationshipsWorker(
  action: {
    type: NFCUActionTypes;
    data: IGetRelationshipsPayload;
  },
  retryAttempt = 0,
): any {
  try {
    const response: IGetRelationshipResponse = yield nfcuService.getRelationships(action.data);
    yield put(NFCUActions.getAccountRelationshipsSuccess(response));
  } catch (error) {
    if (error instanceof Error && retryAttempt === 1) {
      yield put(
        ToastActions.showToast({
          message: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(NFCUActions.getAccountRelationshipsError(error));
    } else {
      yield getAccountsRelationshipsWorker(action, retryAttempt + 1);
    }
  }
}

export function* nfcuWatcher() {
  yield takeEvery(
    NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_START,
    getAccountsRelationshipsWorker,
  );
}
