import {
  IChatSessionPayload,
  ICreateSessionPayload,
  ICreateSessionResponse,
  IGenAISessionDetail,
  IGenAISearchHistory,
  IAutoCompleteSearchPayload,
  AutoCompleteSearchResponse,
  IArchiveChatPayload,
  IUploadGenAiDocumentPayload,
} from '../../models/GenAIModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class GenAIService {
  private readonly tangraDcaService: ApiServiceBase = new ApiService(
    ServiceType.BuildDeploy,
    ServerType.dca,
  );

  private readonly dcaService: ApiServiceBase = new ApiService(
    ServiceType.BuildDeploy,
    ServerType.dca,
  );

  public fetchRecentSearchListApi(
    payload: IChatSessionPayload,
  ): Promise<Array<IGenAISearchHistory>> | Array<IGenAISearchHistory> {
    const route: string[] = [API_ROUTE.GEN_AI.BASE, API_ROUTE.LIST];
    return this.tangraDcaService.get({ route: route, query: { user_id: payload.userId } });
  }

  public fetchSessionDetailApi(
    payload: IChatSessionPayload,
  ): Promise<Array<IGenAISessionDetail>> | Array<IGenAISessionDetail> {
    const route: string[] = [API_ROUTE.GEN_AI.BASE, API_ROUTE.GEN_AI.DETAILS];
    return this.tangraDcaService.get({ route: route, query: { user_id: payload.userId } });
  }

  public fetchCurrentChatSession(
    data: ICreateSessionPayload,
  ): Promise<ICreateSessionResponse> | ICreateSessionResponse {
    if (data.isDocSearch) {
      const route: string[] = [API_ROUTE.GEN_AI.ALTERNATE, API_ROUTE.GEN_AI.DOCUMENT_SEARCH];
      return this.dcaService.postMultipart({ route }, [
        {
          name: 'data',
          content: JSON.stringify({
            query: data.query,
            user_id: data.userId,
            session_id: data.sessionId,
          }),
        },
      ]);
    }
    const route: string[] = [API_ROUTE.GEN_AI.BASE];

    return this.tangraDcaService.post(
      { route },
      { query: data.query, user_id: data.userId, session_id: data.sessionId },
    );
  }

  public fetchAutoCompleteOptions(
    data: IAutoCompleteSearchPayload,
  ): Promise<AutoCompleteSearchResponse> | AutoCompleteSearchResponse {
    const route: string[] = [API_ROUTE.GEN_AI.AUTO_COMPLETE_OPTIONS];
    return this.tangraDcaService.get({ route: route, query: { search_text: data.searchText } });
  }

  public archiveChat(data: IArchiveChatPayload): Promise<string> | string {
    const route: string[] = [API_ROUTE.GEN_AI.BASE, API_ROUTE.GEN_AI.ARCHIVE];
    return this.tangraDcaService.post(
      { route },
      {
        user_id: data.userId,
        session_id: data.sessionId,
        is_deleted: data.isDeleted,
        is_archived: data.isArchived,
      },
    );
  }

  public uploadDocument(data: IUploadGenAiDocumentPayload): Promise<string> | string {
    const route: string[] = [API_ROUTE.GEN_AI.BASE, API_ROUTE.GEN_AI.DOCUMENT_UPLOAD];
    return this.tangraDcaService.post({ route }, data);
  }
}

const genAIService = new GenAIService();
export default genAIService;
