import { Reducer } from 'redux';
import IChatbotState from '../state/ChatbotState';
import { ChatbotAction, ChatbotActionTypes } from '../actions/ChatbotActions';
import { IQueryResponse } from '../../models/ChatbotModels';

export const initialState: IChatbotState = {
  data: { question: '', response: '' },
  error: undefined,
  loading: false,
};

export const ChatbotReducer: Reducer<IChatbotState> = (
  state = initialState,
  action: ChatbotAction,
) => {
  switch (action.type) {
    case ChatbotActionTypes.GET_CHAT_RESPONSE_START:
      return { ...state, loading: true };

    case ChatbotActionTypes.GET_CHAT_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data as IQueryResponse,
      };
    case ChatbotActionTypes.GET_CHAT_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ChatbotActionTypes.RELOAD:
    case ChatbotActionTypes.CLEAR_RESPONSE:
      return { ...initialState };
    default:
      return state;
  }
};
