import { isEmpty, isNull } from 'lodash';
import moment from 'moment';

//Method to open url in new tab
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const getCurrencyFormat = (amount: number) => {
  return currencyFormatter.format(amount);
};

export const getTimestampString = (timestamp: string) => {
  return moment(timestamp).format('MMM Do, YYYY');
};

export const checkObjectFilled = (inputObject: Record<string, any>): boolean => {
  return Object.values(inputObject).reduce((prevValue: boolean, value): boolean => {
    if (typeof value === 'number') {
      return prevValue && Number(value) > 0;
    }
    return prevValue && !['', [], {}, undefined, null].includes(value);
  }, true);
};
