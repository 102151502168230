import { Reducer } from 'redux';
import ITransactionState from '../state/TransactionState';
import { TransactionAction, TransactionActionTypes } from '../actions/TransactionActions';
import { IContact, ITransaction } from '../../models/TransactionModels';

export const initialState: ITransactionState = {
  transactions: [],
  balance: 0,
  contacts: [],
  error: undefined,
  loading: false,
};

export const transactionReducer: Reducer<ITransactionState> = (
  state = initialState,
  action: TransactionAction,
) => {
  switch (action.type) {
    case TransactionActionTypes.GET_TRANSACTIONS_START:
    case TransactionActionTypes.GET_BALANCE_START:
    case TransactionActionTypes.GET_CONTACTS_START:
    case TransactionActionTypes.DEPOSIT_FUNDS_START:
    case TransactionActionTypes.SEND_PAYMENT_START:
      return { ...state, loading: true };

    case TransactionActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.data as Array<ITransaction>,
      };
    case TransactionActionTypes.GET_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        balance: action.data as number,
      };
    case TransactionActionTypes.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.data as Array<IContact>,
      };

    case TransactionActionTypes.DEPOSIT_FUNDS_SUCCESS:
    case TransactionActionTypes.SEND_PAYMENT_SUCCESS:
    case TransactionActionTypes.DEPOSIT_FUNDS_ERROR:
    case TransactionActionTypes.SEND_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case TransactionActionTypes.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        transactions: [],
      };
    case TransactionActionTypes.GET_BALANCE_ERROR:
      return {
        ...state,
        loading: false,
        balance: 0,
      };
    case TransactionActionTypes.GET_CONTACTS_ERROR:
      return {
        ...state,
        loading: false,
        contacts: [],
      };
    case TransactionActionTypes.LOGOUT:
      return { ...initialState };

    case TransactionActionTypes.RELOAD:
      return { ...initialState, loading: false };

    default:
      return state;
  }
};
