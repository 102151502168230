import { IOpenModalPayload } from '../../models/CommonModels';
import { IStoreAction } from '../StoreHelper';

export enum CommonActionTypes {
  SET_MODAL_OPEN = 'SET_MODAL_OPEN',
  SET_MODAL_CLOSE = 'SET_MODAL_CLOSE',
  HARD_RELOAD = 'persist/REHYDRATE',
}

export type CommonActionPayload = IOpenModalPayload | Error | null;

export type CommonAction = IStoreAction<CommonActionTypes, CommonActionPayload>;

export class CommonActions {
  public static modalOpen = (data: IOpenModalPayload): CommonAction => ({
    type: CommonActionTypes.SET_MODAL_OPEN,
    data,
  });
  public static modalClose = (): CommonAction => ({
    type: CommonActionTypes.SET_MODAL_CLOSE,
  });
}
