import { Reducer } from 'redux';
import ICommonState from '../state/CommonState';
import { ModalTypes } from '../../../AppConstants';
import { CommonAction, CommonActionTypes } from '../actions/CommonActions';
import { IOpenModalPayload } from '../../models/CommonModels';

export const initialState: ICommonState = {
  modal: {
    modalType: ModalTypes.NONE,
    header: '',
    content: null,
  },
};

export const CommonReducer: Reducer<ICommonState> = (
  state = initialState,
  action: CommonAction,
) => {
  switch (action.type) {
    case CommonActionTypes.SET_MODAL_OPEN:
      return { ...state, modal: action.data as IOpenModalPayload };

    case CommonActionTypes.SET_MODAL_CLOSE:
    case CommonActionTypes.HARD_RELOAD:
      return {
        ...state,
        modal: {
          modalType: ModalTypes.NONE,
          header: '',
          content: null,
        },
      };
    default:
      return state;
  }
};
