export const pageSizeOptions = ['5', '10', '15', '20'];

export const devEnvironments = ['tangradev.deloitte.com', 'dev.dca.deloitte.com'];
export const localhostEnvironments = ['localhost'];
export const demoEnvironments = ['demo.dca.deloitte.com'];
export const termsandConditionsUrl =
  'https://www2.deloitte.com/us/en/footerlinks1/deloitte-application-privacy-notice.html';
export enum Environment {
  DEV = 'dev',
  PROD = 'prod',
  LOCALHOST = 'localhost',
}

export enum ModalTypes {
  NONE = 'NONE',
  SEND_PAYMENT = 'SEND_PAYMENT',
  DEPOSIT_FUNDS = 'DEPOSIT_FUNDS',
}

export const DUMMY_ACCESS_TOKEN = '1';
