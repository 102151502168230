import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar, closeSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { ToastActions } from '../../libs/store/actions/ToastActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';

const Layout = () => {
  const { toastList, loggedIn } = useAppSelector(
    ({ toast: { toastList }, login: { user, expiry } }: IApplicationState) => ({
      toastList,
      loggedIn: !isEmpty(user) && !moment().isAfter(moment(expiry * 1000)),
    }),
  );
  const dispatch = useDispatch();

  const dispatcher = {
    hideToast: () => dispatch(ToastActions.hideToast()),
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (toastList.length > 0) {
      enqueueSnackbar(toastList[0].message, {
        variant: toastList[0].type,
        action: (snackbarId) => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(snackbarId)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
      dispatcher.hideToast();
    }
  }, [toastList.length]);

  return (
    <Grid container className="">
      <Loader />
      <Header />
      <Modal theme="" />
      <Router>
        <Switch>
          <Route exact path={routes.login}>
            <Login />
          </Route>
          <Route exact path={routes.dashboard}>
            {loggedIn ? <Dashboard /> : <Redirect to={routes.login} />}
          </Route>
          <Route exact path={'*'}>
            {loggedIn ? <Redirect to={routes.dashboard} /> : <Redirect to={routes.login} />}
          </Route>
        </Switch>
      </Router>
    </Grid>
  );
};

export default Layout;
