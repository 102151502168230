import { IGetRelationshipsPayload, IGetRelationshipResponse } from '../../models/NFCUModels';
import { IStoreAction } from '../StoreHelper';

export enum NFCUActionTypes {
  GET_NFCU_ACCOUNT_RELATIONSHIPS_START = 'GET_NFCU_ACCOUNT_RELATIONSHIPS_START',
  GET_NFCU_ACCOUNT_RELATIONSHIPS_SUCCESS = 'GET_NFCU_ACCOUNT_RELATIONSHIPS_SUCCESS',
  GET_NFCU_ACCOUNT_RELATIONSHIPS_ERROR = 'GET_NFCU_ACCOUNT_RELATIONSHIPS_ERROR',
  RELOAD = 'persist/REHYDRATE',
}

export type NFCUActionPayload = IGetRelationshipsPayload | IGetRelationshipResponse | Error | null;

export type NFCUAction = IStoreAction<NFCUActionTypes, NFCUActionPayload>;

export class NFCUActions {
  public static getAccountRelationshipsStart = (data: IGetRelationshipsPayload): NFCUAction => ({
    type: NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_START,
    data,
  });
  public static getAccountRelationshipsSuccess = (data: IGetRelationshipResponse): NFCUAction => ({
    type: NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_SUCCESS,
    data,
  });
  public static getAccountRelationshipsError = (data: Error): NFCUAction => ({
    type: NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_ERROR,
    data,
  });
}
