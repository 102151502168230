import { Reducer } from 'redux';
import IGenAIState from '../state/GenAIState';
import { GenAIAction, GenAIActionTypes } from '../actions/GenAIActions';
import {
  AutoCompleteSearchResponse,
  ICreateSessionResponse,
  IGenAISearchHistory,
} from '../../models/GenAIModels';

export const initialState: IGenAIState = {
  recentSearchList: [],
  sessionDetail: [],
  currentChatSession: {} as ICreateSessionResponse,
  autoCompleteOptions: [],
  error: undefined,
  loading: false,
  loadingCount: 0,
};

export const genAIReducer: Reducer<IGenAIState> = (state = initialState, action: GenAIAction) => {
  switch (action.type) {
    case GenAIActionTypes.FETCH_CHAT_SESSION_LIST_REQUEST:
    case GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_START:
    case GenAIActionTypes.POST_SESSION_START:
      return { ...state, loadingCount: state.loadingCount + 1, error: undefined };
    case GenAIActionTypes.FETCH_AUTO_COMPLETE_START:
      return { ...state, error: undefined, loading: true };
    case GenAIActionTypes.ARCHIVE_CHAT_START:
    case GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_START:
      return { ...state, loading: true };

    case GenAIActionTypes.FETCH_CHAT_SESSION_LIST_SUCCESS:
      return {
        ...state,
        loadingCount: Math.max(0, state.loadingCount - 1),
        recentSearchList: action.data as Array<IGenAISearchHistory>,
      };
    case GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_SUCCESS:
      return {
        ...state,
        loadingCount: Math.max(0, state.loadingCount - 1),
        sessionDetail: action.data as Array<IGenAISearchHistory>,
      };
    case GenAIActionTypes.POST_SESSION_SUCCESS:
      return {
        ...state,
        loadingCount: Math.max(0, state.loadingCount - 1),
        currentChatSession: action.data as ICreateSessionResponse,
      };
    case GenAIActionTypes.FETCH_AUTO_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        autoCompleteOptions: action.data as AutoCompleteSearchResponse,
      };
    case GenAIActionTypes.ARCHIVE_CHAT_SUCCESS:
    case GenAIActionTypes.ARCHIVE_CHAT_ERROR:
    case GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_SUCCESS:
    case GenAIActionTypes.UPLOAD_GEN_AI_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GenAIActionTypes.FETCH_CHAT_SESSION_LIST_ERROR:
    case GenAIActionTypes.FETCH_CHAT_SESSION_DETAIL_ERROR:
    case GenAIActionTypes.POST_SESSION_ERROR:
      return { ...state, loadingCount: Math.max(0, state.loadingCount - 1), error: action.data };
    case GenAIActionTypes.FETCH_AUTO_COMPLETE_ERROR:
      return { ...state, error: action.data, loading: false };

    case GenAIActionTypes.GEN_AI_HARD_RELOAD:
      return {
        ...state,
        loading: false,
        loadingCount: 0,
      };

    default:
      return state;
  }
};
