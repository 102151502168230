import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './NFCUComponent.less';
import { NFCUActions } from '../../libs/store/actions/NFCUActions';
import { nfcuDummyPayload } from '../../theme/ThemeLabels';

const NFCUComponent: React.FC<any> = () => {
  const { accounts, debitCards } = useAppSelector(
    ({ nfcu: { accounts, debitCards } }: IApplicationState) => ({
      accounts,
      debitCards,
    }),
  );
  const dispatch = useDispatch();

  const dispatcher = {
    getRelationships: () => {
      return dispatch(NFCUActions.getAccountRelationshipsStart(nfcuDummyPayload));
    },
  };
  useEffect(() => {
    dispatcher.getRelationships();
  }, []);
  return (
    <Grid container justifyContent={'center'} className="nfcu-table-container">
      <Grid item xs={1} sx={{ margin: '1rem 2rem' }}>
        {'Accounts'}
      </Grid>
      <Grid item xs={11} className="nfcu-table">
        <Table>
          <TableHead>
            <TableRow>
              {accounts.length &&
                Object.keys(accounts[0]).map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((row: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {accounts.length &&
                  Object.keys(accounts[0]).map((header) => (
                    <TableCell key={header}>{row[header] || 'NA'}</TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid container className="separator" />
      <Grid item xs={1} sx={{ margin: '1rem 2rem' }}>
        {'Debit Cards'}
      </Grid>
      <Grid item xs={11} className="nfcu-table">
        <Table>
          <TableHead>
            <TableRow>
              {debitCards.length &&
                Object.keys(debitCards[0]).map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {debitCards.map((row: any, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {debitCards.length &&
                  Object.keys(debitCards[0]).map((header) => (
                    <TableCell key={header}>{row[header] || 'NA'}</TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default NFCUComponent;
