import { Reducer } from 'redux';
import INFCUState from '../state/NFCUState';
import { NFCUAction, NFCUActionTypes } from '../actions/NFCUActions';
import { IGetRelationshipResponse } from '../../models/NFCUModels';

export const initialState: INFCUState = {
  accounts: [],
  debitCards: [],
  error: undefined,
  loading: false,
};

export const NFCUReducer: Reducer<INFCUState> = (state = initialState, action: NFCUAction) => {
  switch (action.type) {
    case NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_START:
      return { ...state, loading: true };

    case NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: (action.data as IGetRelationshipResponse).accounts,
        debitCards: (action.data as IGetRelationshipResponse).debitCards,
      };
    case NFCUActionTypes.GET_NFCU_ACCOUNT_RELATIONSHIPS_ERROR:
    case NFCUActionTypes.RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
