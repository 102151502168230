import { all, call, spawn } from 'redux-saga/effects';
import { genAIWatcher } from './GenAISaga';
import { loginWatcher } from './LoginSaga';
import { transactionWatcher } from './TransactionSaga';
import { nfcuWatcher } from './NFCUSaga';
import { chatbotWatcher } from './ChatbotSaga';

export default function* sagas() {
  const sagas = [genAIWatcher, loginWatcher, transactionWatcher, nfcuWatcher, chatbotWatcher];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
          }
        }
      }),
    ),
  );
}
