import { useEffect, useState } from 'react';

class Storage {
  private readonly localstorage = window.localStorage;

  public setItem(key: string, item: string) {
    this.localstorage.setItem(key, item);
  }

  public getItem(key: string) {
    return this.localstorage.getItem(key);
  }

  public removeItem(key: string) {
    this.localstorage.removeItem(key);
  }

  public clearAll() {
    this.localstorage.clear();
  }
}
export const STORAGE_CONSTANTS = {
  userId: 'userId',
  userType: 'userType',
  accessToken: 'accessToken',
  userName: 'userName',
  email: 'email',
};

export const storage = new Storage();

export const useStorage = (key: string): string | null => {
  const [item, setItem] = useState<string | null>(storage.getItem(key));

  useEffect(() => {
    const handleStorageChange = () => {
      setItem(storage.getItem(key));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, [key]);

  return item;
};
