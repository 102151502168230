import {
  IContactResponse,
  IDepositPayload,
  ISendPaymentPayload,
  ITransaction,
} from '../../models/TransactionModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class TransactionService {
  private readonly TransactionService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.transaction,
  );
  private readonly BalanceService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.balance,
  );
  private readonly ContactsService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.contacts,
  );

  private readonly PaymentsService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.payments,
  );

  public getTransactions(
    accountNumber: string,
  ): Promise<Array<ITransaction>> | Array<ITransaction> {
    const route: string[] = [API_ROUTE.TRANSACTIONS, accountNumber];
    return this.TransactionService.get({ route: route });
  }

  public getBalance(accountNumber: string): Promise<number> | number {
    const route: string[] = [API_ROUTE.BALANCES, accountNumber];
    return this.BalanceService.get({ route: route });
  }

  public getContacts(userId: string): Promise<Array<IContactResponse>> | Array<IContactResponse> {
    const route: string[] = [API_ROUTE.CONTACTS, userId];
    return this.ContactsService.get({ route: route });
  }

  public depositAmount(payload: IDepositPayload): Promise<string> | string {
    const route: string[] = [API_ROUTE.DEPOSIT];
    return this.PaymentsService.post(
      { route: route },
      {
        account_num: parseInt(payload.accountNumber),
        amount: payload.amount,
        new_contact: payload.newContact,
        contact_label: payload.contactLabel,
        routing_num: parseInt(payload.routingNumber),
      },
    );
  }

  public sendPayment(payload: ISendPaymentPayload): Promise<string> | string {
    const route: string[] = [API_ROUTE.PAYMENT];
    return this.PaymentsService.post(
      { route: route },
      {
        account_num: parseInt(payload.accountNumber),
        amount: payload.amount,
        new_contact: payload.newContact,
        contact_label: payload.contactLabel,
      },
    );
  }
}

const transactionService = new TransactionService();
export default transactionService;
