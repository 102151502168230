import React from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import ChatSharpIcon from '@mui/icons-material/ChatSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import './Header.less';
import DcaSvaLogo from '../../assets/images/dca-sba.svg';
import GCPLogo from '../../assets/images/gcp-icon.png';
import AzureLogo from '../../assets/images/azure-icon.png';
import { ApplicationLabels, HeaderLabels, DummyLabels } from '../../theme/ThemeLabels';
import { routes } from '../../navigation/Routes';
import { STORAGE_CONSTANTS, storage } from '../../libs/services/LocalStorage';

const Header: React.FC<any> = () => {
  const { loggedIn, userName } = useAppSelector(
    ({ login: { user, name, expiry } }: IApplicationState) => ({
      loggedIn: !isEmpty(user) && !moment().isAfter(moment(expiry * 1000)),
      userName: name,
    }),
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const dispatcher = {
    logout: () => dispatch(LoginActions.Logout()),
  };

  const getProviderImage = () => {
    switch (process.env.REACT_APP_CLOUD_PROVIDER) {
      case 'azure':
        return AzureLogo;
      case 'gcp':
      default:
        return GCPLogo;
    }
  };
  return (
    <Grid container justifyContent="center" alignItems="center" className="header-container">
      <Grid item xs={3} className="header-left-section">
        <img src={getProviderImage()} alt={'Cloud Service Provider'} className="service-provider" />
        <div className="provider-separator" />
        <img src={DcaSvaLogo} alt={ApplicationLabels.appName} />
      </Grid>
      <Grid
        item
        xs={4}
        className="header-right-section"
        container
        alignItems={'flex-end'}
        justifyContent={'center'}
      >
        {loggedIn ? (
          <Grid
            item
            xs={9}
            container
            direction={'column'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
          >
            <Grid
              container
              item
              xs={8}
              justifyContent={'flex-end'}
              alignItems={'center'}
              columnGap={2}
            >
              <span className="header-text">
                {HeaderLabels.routingNumberLabel} <b>{DummyLabels.routingNumberValue}</b>
              </span>
              <span
                className="header-text signout"
                onClick={() => {
                  storage.setItem(STORAGE_CONSTANTS.accessToken, '');
                  history.replace(routes.login);
                  dispatcher.logout();
                }}
              >
                <LockIcon fontSize={'small'} />
                {HeaderLabels.signOut}
              </span>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent={'flex-end'}
              alignItems={'center'}
              columnGap={1}
            >
              <Grid
                xs={8}
                item
                className="header-tool-box"
                container
                justifyContent={'space-around'}
                alignItems={'center'}
              >
                <AccountCircleIcon fontSize={'large'} />
                <Grid xs={8} item container direction={'column'}>
                  <span className="header-username">{userName}</span>
                  <span className="header-membership">{DummyLabels.membership}</span>
                </Grid>
                <ExpandMoreIcon className="disabled-click" fontSize="large" />
              </Grid>
              <Grid
                xs={1}
                item
                className="header-tool-box disabled-click"
                container
                justifyContent={'center'}
                alignItems={'center'}
              >
                <EmailSharpIcon fontSize={'medium'} />
              </Grid>
              <Grid
                xs={1}
                item
                className="header-tool-box disabled-click"
                container
                justifyContent={'center'}
                alignItems={'center'}
              >
                <ChatSharpIcon fontSize={'medium'} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid xs={9} item container justifyContent={'space-evenly'} alignItems={'center'}>
            <span className="header-text">
              {HeaderLabels.routingNumberLabel} <b>{DummyLabels.routingNumberValue}</b>
            </span>
            <span className="header-text disabled-click">
              <LocationOnIcon fontSize={'small'} />
              {HeaderLabels.locationsLabel}
            </span>
            <span className="header-text disabled-click">
              <PermPhoneMsgIcon fontSize={'small'} />
              {HeaderLabels.contactLabel}
            </span>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
