export const API_ROUTE = {
  ACCOUNT: 'account',
  ADMIN: 'admin',
  ALERT: 'alert',
  ANALYTICS: 'analytics',
  AUDIT: 'audit',
  CHART: 'chart',
  CHAT: 'chat',
  COMPLIANCE: 'compliance',
  DASHBOARD: 'dashboard',
  DCA: 'dca',
  DISCOVERY: 'discovery',
  FEEDBACK: 'feedback',
  GOVERNANCE: 'governance',
  INSTANCE: 'instance',
  KEYS: 'keys',
  LIST: 'list',
  LOGIN: 'login',
  MASTER: 'master',
  NOTIFICATION: 'notification',
  PAGE: 'page',
  PERMISSION: 'permission',
  PIPELINE: 'pipeline',
  PROJECT: 'project',
  QUESTIONNAIRE: 'questionnaire',
  RESPONSE: 'response',
  RULESET: 'ruleset',
  RUN: 'run',
  SERVICES: 'services',
  SOURCE: 'source',
  START: 'start',
  SUMMARY: 'summary',
  TARGET: 'target',
  USER: 'user',
  USERS: 'users',
  VISITS: 'visits',
  ACCOUNTS: 'accounts',
  RELATIONSHIPS: 'relationships',
  GEN_AI: {
    BASE: 'ai-chat-session',
    ALTERNATE: 'ai-chat-sessions',
    AUTO_COMPLETE_OPTIONS: 'ai-chat-autocomplete',
    DETAILS: 'details',
    ARCHIVE: 'archive',
    DOCUMENT_UPLOAD: 'document-upload',
    DOCUMENT_SEARCH: 'document-search',
  },
  TRANSACTIONS: 'transactions',
  BALANCES: 'balances',
  CONTACTS: 'contacts',
  DEPOSIT: 'deposit',
  PAYMENT: 'payment',
  CHATBOT: 'ai-chat',
  NFCU: 'dca-nfcu',
};
