/* eslint-disable max-lines */
import { IGetRelationshipsPayload } from '../libs/models/NFCUModels';

export const ApplicationLabels = {
  appName: 'Deloitte Cloud Accelerator - Sample Banking Application',
  dollar: '$',
};
export const LoginLabels = {
  needHelp: 'Need Help?',
  welcomeMessage: 'Welcome to Digital Banking',
  loginTooltiop:
    'Sign into online banking with your unique Username, which may be your access number.',
  signIn: 'Sign In',
  username: 'Username',
  password: 'Password',
};
export const HeaderLabels = {
  routingNumberLabel: 'Routing Number:',
  locationsLabel: 'Locations',
  contactLabel: 'Contact Us',
  signOut: 'Sign Out',
};
export const AccountsLabels = {
  accounts: 'Accounts',
  editAccounts: 'Edit Accounts View',
  print: 'Print',
  totalCash: 'Total Cash',
  checkingAccount: 'Checking',
  availableBalance: 'Available Balance',
};
export const TransactionsLabels = {
  transferFunds: 'Transfer Funds',
  betweenAccounts: '(Inbound)',
  sendPayment: 'Payments',
  statements: 'Statements',
  billPay: 'Bill Pay',
  showUpcoming: 'Show Upcoming Transactions',
  noUpcoming: 'No Upcoming Transactions',
  transactionHistory: 'Transaction History',
  transactionSubtext:
    'Only posted transactions will display in search and filter results, Refer to your Statements for older transactions.',
  download: 'Download',
  searchPlaceholder: 'Search by transaction description',
  filter: 'Filter',
  details: 'Details',
  amount: 'Amount',
  balance: 'Balance',
  credit: 'Credit',
  debit: 'Debit',
};
export enum DashboardMenu {
  ACCOUNTS = 'Accounts',
  NFCU_API = 'NFCU API',
  MOVE_MONEY = 'Move Money',
  CARDS = 'Cards',
  STATEMENTS = 'Statements',
  MY_MAKING_CENTS = 'My MakingCents',
}
export const ModalLabels = {
  depositHeader: 'Make a Deposit',
  sendPaymentHeader: 'Pay to Account',
  deposit: 'Deposit',
  send: 'Send',
  cancel: 'Cancel',
  externalAccount: 'EXTERNAL ACCOUNT',
  recipient: 'RECIPIENT',
  depositAmount: 'DEPOSIT AMOUNT',
  transactionAmount: 'TRANSACTION AMOUNT',
  newExternalAccount: 'New External Account',
  newRecipient: 'New Recipient',
  accountNumber: 'Account Number',
  routingNumber: 'Routing Number',
  accountLabel: 'Account Label',
  zeroAmount: '0.00',
  sendHeader: '',
};

export const ChatbotLabels = {
  headerLine1: 'Hey!',
  headerLine2: 'How can we help you?',
  placeHolder: 'Ask your Query...',
};
export const DummyLabels = {
  routingNumberValue: '256074974',
  membership: 'Member since 2022',
  accounts: [
    {
      name: 'EveryDay Checking - 0968',
    },
    {
      name: 'EveryDay Checking - 0976',
    },
    {
      name: 'EveryDay Checking - 984',
    },
    {
      name: 'EveryDay Checking - 0406',
    },
    {
      name: 'EveryDay Checking - 0414',
    },
    {
      name: 'EveryDay Checking - 4090',
    },
    {
      name: 'EveryDay Checking - 5557',
    },
    {
      name: 'EveryDay Checking - 3365',
    },
  ],
};

export const nfcuDummyPayload: IGetRelationshipsPayload = {
  NFCURequestHeader: {
    rqUID: '68f9202e-137f-4d9a-8ac1-6f108e817498',
    credential: 'aps-validation-user',
    consumerChannel: 'HBK',
    consumingApplicationName: 'APS',
  },
  accessNumber: '25',
};

export const nfcuDummyResponse: any = {
  NFCUResponseHeader: {
    'rsUID': '68f9202e-137f-4d9a-8ac1-6f108e817498',
    'consumerChannel': 'HBK',
    'consumingApplicationName': 'APS',
    'confirmationNbr': '250007',
    'eor': 'v5762',
    'Status': {
      'statusCode': '0',
      'serverStatusCode': '',
      'severity': 'Info',
      'statusDesc': 'Success',
      'AdditionalStatus': [
        {
          'statusCode': '-1000',
          'serverStatusCode': 'TP0001',
          'severity': 'Info',
          'statusDesc': 'PROCESSING COMPLETE',
        },
        {
          'statusCode': '0',
          'serverStatusCode': 'TP0001',
          'severity': 'Info',
          'statusDesc': 'Success',
        },
        {
          'statusCode': '0',
          'serverStatusCode': '0',
          'severity': 'Info',
          'statusDesc': 'TRANSACTION PROCESSING COMPLETE.',
        },
      ],
    },
  },
  numberOfAccounts: 243,
  numberOfDebitCards: 3,
  accounts: [
    {
      'index': '1',
      'accessNumber': '00000000000025',
      'accountNumber': '7004840968',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'AC',
      'accountStatusDesc': 'Active',
      'rmaccountStatusCode': 'AC',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2024-01-22',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '24503.48',
      'ledgerBalance': '24503.48',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.06',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.060246',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '00-NORMAL',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
    {
      'index': '2',
      'accessNumber': '00000000000025',
      'accountNumber': '7004840976',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'AC',
      'accountStatusDesc': 'Active',
      'rmaccountStatusCode': 'AC',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2024-01-22',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '17',
      'memberRank': '2LT',
      'statusDescription': '00-NORMAL',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'Y',
      'loanAccountInd': 'Y',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'Y',
      'reasonCode': '00',
    },
    {
      'index': '3',
      'accessNumber': '00000000000025',
      'accountNumber': '7004840984',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'AC',
      'accountStatusDesc': 'Active',
      'rmaccountStatusCode': 'AC',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2024-01-22',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '17',
      'memberRank': '2LT',
      'statusDescription': '00-NORMAL',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'Y',
      'loanAccountInd': 'Y',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'Y',
      'reasonCode': '00',
    },
    {
      'index': '4',
      'accessNumber': '00000000000025',
      'accountNumber': '7004840406',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'AC',
      'accountStatusDesc': 'Active',
      'rmaccountStatusCode': 'AC',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2024-01-19',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '00-NORMAL',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
    {
      'index': '5',
      'accessNumber': '00000000000025',
      'accountNumber': '7004840414',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'AC',
      'accountStatusDesc': 'Active',
      'rmaccountStatusCode': 'AC',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2024-01-19',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '00-NORMAL',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
    {
      'index': '6',
      'accessNumber': '00000000000025',
      'accountNumber': '7004794090',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'IN',
      'accountStatusDesc': 'Inactive',
      'rmaccountStatusCode': 'IN',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2023-05-31',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '07-INACTIVE',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
    {
      'index': '7',
      'accessNumber': '00000000000025',
      'accountNumber': '7004793357',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'IN',
      'accountStatusDesc': 'Inactive',
      'rmaccountStatusCode': 'IN',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2023-05-25',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '07-INACTIVE',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
    {
      'index': '8',
      'accessNumber': '00000000000025',
      'accountNumber': '7004793365',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'AC',
      'accountStatusDesc': 'Active',
      'rmaccountStatusCode': 'AC',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2023-05-25',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '00-NORMAL',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
    {
      'index': '9',
      'accessNumber': '00000000000025',
      'accountNumber': '7004699877',
      'fiident': '0001000000000000',
      'memberTypeCode': 'P',
      'fullName': '2LT MARY LATER',
      'primaryAccoutOwnerFullName': 'MARY LATER',
      'subType': 'SK',
      'accountType': 'DDA',
      'productCode': '700',
      'productDescription': 'EveryDay Checking',
      'nickname': '',
      'partyOwnerCode': 'P',
      'memberAccountRelationshipType': 'IND',
      'accountStatus': 'IN',
      'accountStatusDesc': 'Inactive',
      'rmaccountStatusCode': 'IN',
      'memberAccountOwnershipCode': 'PRI',
      'legalResponsibilityCode': 'D',
      'internalTransferFlag': 'B',
      'externalTransferFlag': 'B',
      'usdTransferFlag': 'BB',
      'openDate': '2022-06-02',
      'lastStatementDate': '2024-07-08',
      'currentCycleTransactionCount': '0',
      'availableBalance': '0.00',
      'ledgerBalance': '0.00',
      'holdAmount': '0.00',
      'creditLimit': '0.00',
      'fixedPaymentAmount': '0.00',
      'avgLedgAcctPeriod': 'YTD',
      'anticipatedInterest': '0.00',
      'currentIntRate': '0.010000',
      'accruedInterest': '0.000000',
      'lineOfCreditIndicator': 'N',
      'serviceProvider': 'IM',
      'holdAllFundsInd': '0',
      'duplicateCustIndicator': 'N',
      'memberToMemberIndicator': 'N',
      'scraFlag': 'N',
      'numOfSavingsAccounts': '0',
      'memberRank': '2LT',
      'statusDescription': '07-INACTIVE',
      'checkingAccountInd': 'Y',
      'savingsAccountInd': 'N',
      'loanAccountInd': 'N',
      'creditCardAccountInd': 'N',
      'primaryOwnerToSavingsAcctInd': 'N',
      'reasonCode': '00',
    },
  ],
  debitCards: [
    {
      'cardIndex': '1',
      'cardNumber': '4000220002192256',
      'name': 'TEST;PRINTCARD',
      'memberAccountRelationshipType': 'IND',
      'primaryDDAAcctNumber': '7004436775',
      'cardType': 'L',
      'cardBrand': 'K',
      'cardStatus': '1',
      'cardStatusDescription': 'On Negative File',
      'expirationDate': '2026-08-31',
      'memberRank': '2LT',
      'userType': '3',
      'cardSequenceNumber': '0',
      'activationInd': 'Y',
    },
    {
      'cardIndex': '2',
      'cardNumber': '4000220002071534',
      'name': 'MARY;SMITH',
      'memberAccountRelationshipType': 'IND',
      'primaryDDAAcctNumber': '7004436775',
      'cardType': '',
      'cardBrand': 'K',
      'cardStatus': '1',
      'cardStatusDescription': 'On Negative File',
      'expirationDate': '2026-01-31',
      'memberRank': '2LT',
      'userType': '3',
      'cardSequenceNumber': '0',
      'activationInd': 'Y',
    },
    {
      'cardIndex': '3',
      'cardNumber': '4000220002094254',
      'name': 'MARY;SMITH',
      'memberAccountRelationshipType': 'IND',
      'primaryDDAAcctNumber': '7004436775',
      'primarySavingsAcctNumber': '3003678756',
      'cardType': 'L',
      'cardBrand': 'K',
      'cardStatus': '2',
      'cardStatusDescription': 'Xneg (UnNeg)',
      'expirationDate': '2026-01-31',
      'memberRank': '2LT',
      'userType': '3',
      'cardSequenceNumber': '0',
      'activationInd': 'Y',
    },
  ],
};
